import { postRequest, getRequest } from './base';
import { RECAPTCHA_ACTION } from '../constants/recaptcha';

export const checkShortCodeAvailability = async (params) => {
	return getRequest('/api/space/shortcode/check', params);
};

export const checkMembershipShortCodeAvailability = async (params) => {
	return getRequest('/api/space/membership/shortcode/check', params);
};

export const checkMembershipContractAddressAvailability = async (params) => {
	return getRequest('/api/space/membership/contract_address/availability/check', params);
};

export const checkItemShortCodeAvailability = async (params) => {
	return getRequest('/api/space/item/shortcode/check', params);
};

export const checkItemContractAddressAvailability = async (params) => {
	return getRequest('/api/space/item/contract_address/availability/check', params);
};

export const checkCollectionsContractAddressAvailability = async (params) => {
	return getRequest('/api/space/collections/contract_address/availability/check', params);
};

export const getMySpaces = async (params) => {
	return getRequest('/api/space/my_spaces/get', params);
};

export const getSpace = async (params) => {
	return getRequest('/api/space/get', params);
};

export const createSpace = async (data) => {
	// RECAPTCHA_ACTION.SPACE_CREATE
	return postRequest('/api/space/create', data);
};

export const createSpaceAddDetails = async (data) => {
	// RECAPTCHA_ACTION.SPACE_CREATE_ADD_DETAILS
	return postRequest('/api/space/create/add_details', data);
};

export const createSpaceAddMembership = async (data) => {
	// RECAPTCHA_ACTION.SPACE_CREATE_ADD_MEMBERSHIPS
	return postRequest('/api/space/create/add_membership', data);
};

export const getSpaceMemberships = async (params) => {
	return getRequest('/api/space/memberships/get', params);
};

export const getSpaceMembership = async (params) => {
	return getRequest('/api/space/membership/get', params);
};

export const getSpaceOwnedMemberships = async (params) => {
	return getRequest('/api/space/owned_memberships/get', params);
};

export const createSpaceMembership = async (params) => {
	return postRequest('/api/space/membership/create', params);
};

export const updateSpaceMembership = async (params) => {
	return postRequest('/api/space/membership/update', params);
};

export const getSpaceItems = async (params) => {
	return getRequest('/api/space/items/get', params);
};

export const getSpaceItemData = async (params) => {
	return getRequest('/api/space/item/get', params);
};

export const createSpaceItem = async (params) => {
	return postRequest('/api/space/item/create', params);
};

export const updateSpaceItem = async (params) => {
	return postRequest('/api/space/item/update', params);
};

export const claimSpaceMembership = async (data) => {
	// RECAPTCHA_ACTION.SPACE_MEMBERSHIP_CLAIM
	return postRequest('/api/space/membership/claim', data);
};

export const joinSpaceMembership = async (data) => {
	// RECAPTCHA_ACTION.SPACE_MEMBERSHIP_JOIN
	return postRequest('/api/space/membership/join', data);
};

export const createSpaceAddBenefit = async (data) => {
	// RECAPTCHA_ACTION.SPACE_CREATE_ADD_BENEFITS
	return postRequest('/api/space/create/add_membership_details', data);
};

export const subscribeNewsletter = async (data) => {
	return postRequest('/api/space/newsletter/subscribe', data);
};

export const requestSpaceEmailInvite = async (data) => {
	return postRequest('/api/space/email_invite/request', data);
};

export const createSpaceAddFirstUpdate = async (data) => {
	// RECAPTCHA_ACTION.SPACE_CREATE_ADD_FIRST_UPDATE
	return postRequest('/api/space/create/add_first_update', data);
};

export const createSpaceLaunchStatus = async (data) => {
	return postRequest('/api/space/create/launch_space', data);
};

export const updateSpaceVisibility = async (data) => {
	return postRequest('/api/space/visibility/update', data);
};

export const updateSpaceViewPassword = async (data) => {
	return postRequest('/api/space/view_password/update', data);
};

export const updateSpaceOfflineMessage = async (data) => {
	return postRequest('/api/space/offline_message/update', data);
};

export const updateSpacePlan = async (data) => {
	// RECAPTCHA_ACTION.SPACE_PLAN_UPDATE
	return postRequest('/api/space/plan/update', data);
};

export const getSpaceUpdates = async (params) => {
	return getRequest('/api/space/updates/get', params);
};

export const getSpaceUpdateData = async (params) => {
	return getRequest('/api/space/update/get', params);
};

export const createSpacePostUpdate = async (params) => {
	return postRequest('/api/space/update/post_create', params);
};

export const updateSpacePostUpdate = async (params) => {
	return postRequest('/api/space/update/post_update', params);
};

export const deleteSpacePostUpdate = async (params) => {
	return postRequest('/api/space/update/post_delete', params);
};

export const setSpaceProStyle = async (data) => {
	// RECAPTCHA_ACTION.SPACE_PRO_STYLE_SET
	return postRequest('/api/space/style/pro/set', data);
};

export const updateSpaceProNavigation = async (data) => {
	// RECAPTCHA_ACTION.SPACE_PRO_NAVIGATIONS_UPDATE
	return postRequest('/api/space/navigations/pro/set', data);
};

export const postUserSpaceMembershipClaimAndSignup = async (data) => {
	// RECAPTCHA_ACTION.SPACE_MEMBERSHIP_CLAIM_AND_SIGNUP
	return postRequest('/api/user/space/membership/claim_and_signup', data);
};

export const postUserSpaceMembershipClaimAndSetupSharing = async (data) => {
	// RECAPTCHA_ACTION.SPACE_MEMBERSHIP_CLAIM_AND_SETUP_SHARING
	return postRequest('/api/user/space/membership/claim_and_setup_sharing', data);
};

export const postUserSpaceItemClaimAndSignup = async (data) => {
	return postRequest('/api/user/space/item/claim_and_signup', data);
};

export const postUserSpaceItemClaimAndSetupSharing = async (data) => {
	return postRequest('/api/user/space/item/claim_and_setup_sharing', data);
};

export const getMembersList = async (params) => {
	return getRequest('/api/space/members/list/get', params);
};

export const getCheckSpaceMembership = async (params) => {
	return getRequest('/api/space/member/check-membership', params);
};

export const getMemberData = async (params) => {
	return getRequest('/api/space/member/get', params);
};

export const blockSpaceMember = async (data) => {
	return postRequest('/api/space/member/block', data);
};

export const unblockSpaceMember = async (data) => {
	return postRequest('/api/space/member/unblock', data);
};

export const getSpaceCategories = async (params) => {
	// PARAMS
	// space_id
	return getRequest('/api/space/categories/get', params);
};

export const getSpaceCategoriesList = async (params) => {
	return getRequest('/api/space/categories/list/get', params);
};

export const getSpaceCategoryData = async (params) => {
	// PARAMS
	// category_id
	return getRequest('/api/space/category/get', params);
};

export const createSpaceCategory = async (params) => {
	// PARAMS
	// space_id
	// user_id
	// name
	// slug (small letters with no space)
	return postRequest('/api/space/category/create', params);
};

export const updateSpaceCategory = async (params) => {
	// PARAMS
	// category_id
	// name
	// slug (small letters with no space)
	return postRequest('/api/space/category/update', params);
};

export const deleteSpaceCategory = async (params) => {
	// PARAMS
	// category_id
	return postRequest('/api/space/category/delete', params);
};

export const getMembershipBenefits = async (data) => {
	return getRequest('/api/space/membership/benefits/get', data);
};

export const getMembershipBenefit = async (data) => {
	return getRequest('/api/space/membership/benefit/get', data);
};

export const addMembershipBenefit = async (data) => {
	return postRequest('/api/space/membership/benefit/add', data);
};

export const updateMembershipBenefit = async (data) => {
	return postRequest('/api/space/membership/benefit/update', data);
};

export const getItemBenefits = async (data) => {
	return getRequest('/api/space/item/benefits/get', data);
};

export const getItemBenefit = async (data) => {
	return getRequest('/api/space/item/benefit/get', data);
};

export const addItemBenefit = async (data) => {
	return postRequest('/api/space/item/benefit/add', data);
};

export const updateItemBenefit = async (data) => {
	return postRequest('/api/space/item/benefit/update', data);
};

export const updateSpaceWelcomeSection = async (data) => {
	return postRequest('/api/space/welcome_section/update', data);
};

export const updateSpaceWelcomeLinksSection = async (data) => {
	return postRequest('/api/space/welcome_section/links/update', data);
};

export const setSpaceInformation = async (data) => {
	return postRequest('/api/space/manage/info-pages', data);
};

export const getSpaceInformation = async (data) => {
	return getRequest('/api/space/manage/info-pages/get', data);
};

export const getSpaceInformations = async (data) => {
	return getRequest('/api/space/manage/info-pages/list/get', data);
	// return postRequest('/api/space/manage/info-pages', data);
};

export const getExplicitContent = async (data) => {
	return getRequest('/api/space/explicit-content/get', data);
};

export const setSpacePageInformation = async (data) => {
	return postRequest('/api/space/pages', data);
};

export const setSpaceBusinessInformation = async (data) => {
	return postRequest('/api/space/business-info', data);
};

export const getSpaceBusinessInformation = async (data) => {
	return getRequest('/api/space/business-info/get', data);
};

export const inviteProfileEmails = async (data) => {
	return postRequest('/api/space/manage/invite-profile', data);
};

export const addSpaceContact = async (data) => {
	return postRequest('/api/space/contact/add', data);
};

export const sendEmailSpaceContact = async (data) => {
	return postRequest('/api/space/contact/send-email', data);
};

export const getSpaceModelComments = async (params) => {
	return getRequest('/api/space/comments/get', params);
};

export const createSpaceModelComment = async (params) => {
	return postRequest('/api/space/comment/create', params);
};

export const updateSpaceModelComment = async (params) => {
	return postRequest('/api/space/comment/update', params);
};

export const deleteSpaceComment = async (params) => {
	return postRequest('/api/space/comment/delete', params);
};

export const userUpdateHeartComment = async (data) => {
	return postRequest('/api/space/comment/heart', data);
};

export const userUpdateUnHeartComment = async (data) => {
	return postRequest('/api/space/comment/unheart', data);
};

export const userPostHeartComment = async (data) => {
	return postRequest('/api/space/comment/post/heart', data);
};

export const userPostUnHeartComment = async (data) => {
	return postRequest('/api/space/comment/post/unheart', data);
};

export const getSpaceChannels = async (params) => {
	return getRequest('/api/space/channels/get', params);
};

export const getSpaceChannel = async (params) => {
	return getRequest('/api/space/channel/get', params);
};

export const getSpaceFirstChannel = async (params) => {
	return getRequest('/api/space/first_channel/get', params);
};

export const getSpaceDefaultChannel = async (params) => {
	return getRequest('/api/space/default_channel/get', params);
};

export const createSpaceChannel = async (data) => {
	return postRequest('/api/space/channel/create', data);
};

export const updateSpaceChannel = async (data) => {
	return postRequest('/api/space/channel/update', data);
};

export const deleteSpaceChannel = async (params) => {
	return postRequest('/api/space/channel/delete', params);
};

export const createStripeAccount = async (data) => {
	return postRequest('/api/payment_gateway/account/create', data);
};

export const createStripePayoutAccount = async (data) => {
	return postRequest('/api/payment_gateway/payout_account/create', data);
};

export const createNewCreditCard = async (data) => {
	return postRequest('/api/payment_gateway/payment_method/create', data);
};

export const searchInputTags = async (params) => {
	return postRequest('/api/space/channel/tags/search/get', params);
};

export const enableDisableChannels = async (data) => {
	return postRequest('/api/space/channels/enable', data);
};

export const createStripeCustomerSessionPaymentIntent = async (data) => {
	return postRequest('/api/payment_gateway/checkout_session/onetime_pay/create', data);
};

export const getSpacePayoutMethods = async (params) => {
	return getRequest('/api/payment_gateway/space/payout_account/get', params);
};

export const removeSpacePayoutAccount = async (params) => {
	return postRequest('/api/payment_gateway/space/payout_account/remove', params);
};

export const createUpdateMembershipDetails = async (params) => {
	return postRequest('/api/space/membership/details/create', params);
};

export const getMembershipDetails = async (params) => {
	return getRequest('/api/space/membership/details/get', params);
};
export const setSpaceProDomain = async (data) => {
	return postRequest('/api/space/domain/set', data);
};
export const setSpaceProPageElements = async (data) => {
	return postRequest('/api/space/page-elements/set', data);
};

export const updateLastStepLink = async (data) => {
	// RECAPTCHA_ACTION.SPACE_CREATE_ADD_BENEFITS
	return postRequest('/api/space/last_step_link/update', data);
};

export const createSpaceAccessGroup = async (params) => {
	return postRequest('/api/space/access_group/create', params);
};

export const getSpaceAccessGroup = async (params) => {
	return getRequest('/api/space/access_group/get', params);
};