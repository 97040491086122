import React, {memo, useCallback, useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Dialog, Popover, Listbox } from '@headlessui/react';

import HeaderProMenu from './HeaderProMenu';
import routes from '../../../../routes';

import co_logo from '../../../../assets/images/co_logo.svg';
import HeaderProMenuSidebar from './HeaderProMenuSidebar/HeaderProMenuSidebar';
import {CUSTOM_REGEX} from "../../../../constants/custom_regex";
import default_user_profile from "../../../../assets/images/default_user_profile.png";
import {getDataByUsername} from "../../../../services/general";
function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
  }

const HeaderPro = (props) => {
	const {
		spaceData,
		activeTab,
		onSelectedTabChange,
		viewUsername,
		viewProfileUsername,
		handleSpaceViewData
	} = props;
	const pro_styles = spaceData && spaceData.pro_style ? spaceData.pro_style : null;
	const { authUserInfo } = useSelector((state) => state.general);
	const [profileAssetType, setProfileAssetType] = useState(null);
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const navigate = useNavigate();
	const currentLocation = useLocation();
	const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);
	const [isSpaceLoaded, setIsSpaceLoaded] = useState(false);

	let isPreventSplitHeader = (currentLocation.pathname.includes(routes.viewUserSpace() + viewUsername + '/channel')) ||
		(currentLocation.pathname === (routes.viewUserSpace() + viewUsername + '/login')) ||
		(currentLocation.pathname === (routes.viewUserSpace() + viewUsername + '/signup')) ||
		(currentLocation.pathname === (routes.viewUserSpace() + viewUsername + '/forgot-password')) ||
		(currentLocation.pathname === (routes.viewUserSpace() + viewUsername + '/member-profile')) ||
		(currentLocation.pathname === (routes.spaceMembershipPaymentMethod().replace(':space_username', '@' + viewUsername))) ||
		(currentLocation.pathname === (routes.spaceMembershipPaymentCompleted().replace(':space_username', '@' + viewUsername))) ||
		(currentLocation.pathname === (routes.spaceMembershipRegisterMemberProfile().replace(':space_username', '@' + viewUsername))) ||
		(currentLocation.pathname === (routes.spaceMembershipActivate().replace(':space_username', '@' + viewUsername))) ||
		(currentLocation.pathname === (routes.spaceMembershipWalletSetAccountEmailSent().replace(':space_username', '@' + viewUsername))) ||
		(currentLocation.pathname === (routes.spaceMembershipEmailVerificationWalletSetPassword().replace(':space_username', '@' + viewUsername))) ||
		(currentLocation.pathname === (routes.spaceItemPaymentMethod().replace(':space_username', '@' + viewUsername))) ||
		(currentLocation.pathname === (routes.spaceItemPaymentCompleted().replace(':space_username', '@' + viewUsername))) ||
		(currentLocation.pathname === (routes.spaceItemActivate().replace(':space_username', '@' + viewUsername))) ||
		(currentLocation.pathname === (routes.spaceItemActivated().replace(':space_username', '@' + viewUsername))) ||
		(currentLocation.pathname === (routes.spaceItemWalletConnect().replace(':space_username', '@' + viewUsername))) ||
		(currentLocation.pathname === (routes.spaceItemErrorConnect().replace(':space_username', '@' + viewUsername))) ||
		(currentLocation.pathname === (routes.spaceItemWalletSetAccountEmailSent().replace(':space_username', '@' + viewUsername))) ||
		(currentLocation.pathname === (routes.spaceItemEmailVerificationWalletSetPassword().replace(':space_username', '@' + viewUsername))) ||
		(currentLocation.pathname === (routes.spaceItemRegisterMemberProfile().replace(':space_username', '@' + viewUsername))) ||
		(CUSTOM_REGEX.SPACE_VERIFY_EMAIL_PATH.test(currentLocation.pathname))

	let left_logo = co_logo;
    let logo_type = 'image';
	if ((spaceData && spaceData.plan === 'pro') || isPreventSplitHeader) {
		left_logo = spaceData && spaceData.video ? spaceData.video : spaceData && spaceData.photo_thumb ? spaceData.photo_thumb : co_logo;
		left_logo = pro_styles && pro_styles.logo ? pro_styles.logo : left_logo;

        if(spaceData && (left_logo === spaceData.video)) {
            logo_type = 'video';
        }
	}

	const fetchViewData = useCallback(
		async (username) => {
			try {
				setIsSpaceLoaded(false);
				const data = {
					username: username
				};
				const response = await getDataByUsername(data);
				if (response.result) {
					handleSpaceViewData(response.space);
				} else {

				}
				setIsSpaceLoaded(true);
			} catch (error) {
				console.log(error);
			}
		},
		[]
	);

	const toggleDropdownMenu = () => {
		setIsDropdownMenuOpen(!isDropdownMenuOpen);
	};

	const setActiveTabChange = (item) => {
		// console.log('setActiveTabChange: ' + item);
		onSelectedTabChange(item);
	};

	useEffect(() => {
		if (authUserInfo) {
			if (authUserInfo?.profile?.photo_large) {
				setProfileAssetType('image');
			}
			if (authUserInfo?.profile?.video) {
				setProfileAssetType('video');
			}
		}
		// console.log(currentLocation.pathname)

	}, [authUserInfo, activeTab]);

	useEffect(()=> {
		if (currentLocation.pathname.includes(viewUsername)) {
			fetchViewData(viewUsername).then(() => {});
		}
	},[currentLocation.pathname])

	useEffect(()=> {
	},[isPreventSplitHeader, spaceData])

    const getProLogo = () => {
        // Main = logo
        // Alt = altThemeLogoVideo
        // header = header_background_image
        // footer = header_background_video
        let main = pro_styles?.logo;
        let alt = pro_styles?.altThemeLogoVideo;
        let header = pro_styles?.header_background_image;
        let footer = pro_styles?.header_background_video;
        let selected_theme = pro_styles?.home_theme;
        if(location.pathname.includes('update')){
            selected_theme = pro_styles?.updates_theme;
        }else if(location.pathname.includes('benefit')){
            selected_theme = pro_styles?.benefits_theme;
        }else if(location.pathname.includes('member')){
            selected_theme = pro_styles?.members_theme;
        }
        
        if(pro_styles){
            if(header){
                return header;
            }
            else if(alt){
                if(selected_theme === 'main'){
                    return main;
                }else{
                    return alt;
                }
            }
            else if(main){
                if(selected_theme === 'main'){
                    return main;
                }else{
                    return alt;
                }
            }else{
                return co_logo;
            }
        }else{
            return co_logo;
        }
    }

	return (
		<header id="cs-space-header"
				className={`cs-header header-base ${pro_styles ? pro_styles?.header_theme === 'main' ? 'theme' : 'theme-alt' : 'theme' }  z-10
				 ${isPreventSplitHeader && !viewProfileUsername ? '' :  (pro_styles && pro_styles?.header_layout !== 'standard' && !viewProfileUsername) ? 'split' : ''}`}
		>
			<div id="header-frame" className="header-flex p-header-safeview">
				<nav>
					<div id="cs-space-header-logo" className="flex">
                        <a href={(spaceData && spaceData.username? routes.viewUserSpace() + spaceData.username : '/')} target="_self" onClick={(event) => setActiveTabChange('home')}>
							<img src={getProLogo()} alt={spaceData?.name}/>
						</a>
					</div>
					<HeaderProMenu
						spaceData={spaceData}
						activeTab={activeTab}
						onSelectedTabChange={setActiveTabChange}
					/>
					<div id="cs-space-header-user-nav">
						{!authUserInfo && (
							<div className="cs-stack-user-nav-public">
								<a href={
									viewUsername ? routes.viewUserSpace() + viewUsername + '/login' : routes.login()
								} target="_self" className="button-secondary-gradient"><span><p>Login</p></span></a>
								<a href={
									viewUsername ? routes.viewUserSpace() + viewUsername + '/signup' : routes.signUp()
								} target="_self" className="button-primary-gradient"><span><p>Sign Up</p></span></a>
							</div>
						)}

						{authUserInfo && (
							<div className="cs-stack-user-nav-member">
								<a id="header-user-nav-menu-toggle" className="button-icon-round-gradient"><span><p><i className="fa-regular fa-ellipsis"></i></p></span></a>
								<a className="button-icon-round-gradient"><span><p><i className="fa-regular fa-search"></i></p></span></a>
								<a className="button-icon-round-gradient"><span><p><i className="fa-regular fa-bell"></i></p></span></a>
								<a className="button-icon-round-gradient"><span><p><i className="fa-regular fa-comments"></i></p></span></a>
								<a onClick={toggleDropdownMenu}>
									{profileAssetType && profileAssetType === 'video' ? (
										<video autoPlay muted loop className='rounded-full object-cover avatar-gradient h-10'>
											<source
												src={authUserInfo?.profile?.video}
												type="video/mp4"
											/>
										</video>
									) : (
										<>
											<img
												className='avatar-gradient'
												src={authUserInfo && authUserInfo?.profile?.photo_thumb ? authUserInfo?.profile?.photo_thumb : default_user_profile}
											/>
										</>
									)}
								</a>
							</div>
						)}
					</div>
				</nav>

				<Dialog
					as="div"
					className="lg:hidden "
					open={mobileMenuOpen}
					onClose={setMobileMenuOpen}
				>
					<div className="fixed inset-0 z-50" />
					<Dialog.Panel className="fixed inset-y-0 right-0 !z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
						<div className='text-right mb-4 text-[32px]'>
							<i className="fa fa-times" aria-hidden="true" onClick={() => setMobileMenuOpen(false)}/>
						</div>
						<div className="flex items-center justify-between">
							<a href="#" className="-m-1.5 p-1.5">
								<span className="sr-only">commonspace</span>
								<img className="h-8 w-auto" src={co_logo} alt="" />
							</a>

							{/* <button
								type="button"
								className="-m-2.5 rounded-md p-2.5 text-gray-700"
								onClick={() => setMobileMenuOpen(false)}
							>
								<span className="sr-only">Close menu</span>
								<XMarkIcon className="h-6 w-6" aria-hidden="true" />
							</button> */}
						</div>
						<div className="mt-6 flow-root">
							<div className="-my-6 divide-y divide-gray-500/10">
								<div className="py-6">
									<section className='-mx-3 block px-3 py-2.5'>
										<Link to="#">
											<i className="fa-solid fa-magnifying-glass gradient-color-txt"></i>
										</Link>
									</section>
									<section className='-mx-3 block px-3 py-2.5'>
										<Link to="#">
											<i className="fa-solid fa-bell gradient-color-txt"></i>
										</Link>
									</section>
									<section className='-mx-3 block px-3 py-2.5'>
										<Link to="#">
											<i className="fa-solid fa-wallet gradient-color-txt"></i>
										</Link>
									</section>
								</div>
							</div>
							<div className="-my-6 divide-y divide-gray-500/10">
								{pro_styles && pro_styles.header_layout === 'standard' && (
									<HeaderProMenuSidebar
										spaceData={spaceData}
										activeTab={activeTab}
										onSelectedTabChange={setActiveTabChange}
									/>

								)}
								{!authUserInfo && (
									<div className="py-6">
										<a href="/login" className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
											<p className='gradient-color-txt' >Log in</p>
										</a>
										<a href="/register" className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
											<p className='gradient-color-txt' >Sign up</p>
										</a>
									</div>
								)}
								{authUserInfo && (
									<div className="py-6">
										<section className='-mx-3 block px-3 py-2.5'>
											<Link to={routes.dashboard()} className="dropdown-item">
												<i className="fa-regular fa-chart-tree-map gradient-color-txt"/>{' '}
												<span className='gradient-color-txt'>Dashboard</span>
											</Link>
										</section>
										<section className='-mx-3 block px-3 py-2.5'>
											<Link to={routes.spaces()} className="dropdown-item">
												<i className="fa-solid fa-solar-system gradient-color-txt"/>{' '}
												<span className='gradient-color-txt'>Spaces</span>
											</Link>
										</section>
										<section className='-mx-3 block px-3 py-2.5'>
											<Link to={routes.profile()} className="dropdown-item">
												<i className="fa-regular fa-id-badge gradient-color-txt"/>{' '}
												<span className='gradient-color-txt'>Profile</span>
											</Link>
										</section>
										<section className='-mx-3 block px-3 py-2.5'>
											<Link to={routes.settings()} className="dropdown-item">
												<i className="fa-regular fa-gear gradient-color-txt"/>{' '}
												<span className='gradient-color-txt'>Settings</span>
											</Link>
										</section>
										<section className='-mx-3 block px-3 py-2.5'>
											<Link to={routes.logout()} className="dropdown-item">
												<i className="fa-regular fa-gear gradient-color-txt"/>{' '}
												<span className='gradient-color-txt'>Logout</span>
											</Link>
										</section>
									</div>
								)}
							</div>
						</div>
					</Dialog.Panel>
				</Dialog>
			</div>

			{/* SECTION MEMBER User Menu Menu */}
			{/* CSJS Current toggle is a simple implementation for demo only. */}
			<div id="cs-platform-user-menu" className={`block-base mr-section-safeview ${isDropdownMenuOpen ? '' : 'hide'} z-10`}>
				<nav>
					<Link to={routes.dashboard()} target="_self">
						<div>
							<span><i className="fa-light fa-chart-tree-map"></i></span>
							<p>Dashboard</p>
						</div>
					</Link>
					{/*<Link target="_self">*/}
					{/*	<div>*/}
					{/*		<span><i className="fa-light fa-list-ul"></i></span>*/}
					{/*		<p>Activity</p>*/}
					{/*	</div>*/}
					{/*</Link>*/}
					<Link to={routes.spaces()} target="_self">
						<div>
							<span><i className="fa-light fa-solar-system"></i></span>
							<p>Spaces</p>
						</div>
					</Link>
					<Link to={routes.collections()} target="_self">
						<div>
							<span><i className="fa-light fa-grid"></i></span>
							<p>Collections</p>
						</div>
					</Link>
					<Link to={routes.profile()} target="_self">
						<div>
							<span><i className="fa-light fa-id-badge"></i></span>
							<p>Profile</p>
						</div>
					</Link>
					<Link to={routes.settings()} target="_self">
						<div>
							<span><i className="fa-light fa-gear"></i></span>
							<p>Account</p>
						</div>
					</Link>
					<Link to={routes.logout()} target="_self">
						<div>
							<span><i className="fa-light fa-arrow-right-from-bracket"></i></span>
							<p>Log Out</p>
						</div>
					</Link>
				</nav>
			</div>
			{/*	!SECTION MEMBER Mobile Menu*/}
		</header>
	);
};
export default memo(HeaderPro);
