import { memo, useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import parse from "html-react-parser";
import routes from '../../../routes';
import { displayDataContent } from '../../../utils/spaceUpdateDataView';
import ReactPlayer from "react-player/lazy";
import default_bg from "../../../assets/images/form_background.png";


const LatestUpdates = (parentProps) => {
	const { data, user, updatesViewingUserIsMember, updateGlobalActiveTab, spaceData } =
		parentProps;
	const [spaceUsername, setSpaceUsername] = useState(null);

	const handleActiveTabChange = () => {
		updateGlobalActiveTab('update_content');
	};

	const redirectLink = (spaceUsername, data) => {
		return routes
			.spaceViewSpaceUpdate()
			.replace(':space_username', '@' + spaceUsername)
			.replace(':update_id', data.id);
	}

	const CategoryRow = (item) => {
		return (
			<>
				<p className="update-tag color-lightest">{item.category.name}</p>
			</>
		);
	};

	const renderContent = (title, previewText) => (
		<>
			<h4>
				<a onClick={() => {handleActiveTabChange();}}
				   href={redirectLink(spaceUsername, data)} target="_self"
				>
					{title || <br />}
				</a>
			</h4>
			<p>{previewText && parse(previewText)}</p>
		</>
	);

	const titlePreviewTextView = data.visibility === 'public'
		? renderContent(data.title, data.preview_text)
		: updatesViewingUserIsMember
			? renderContent(data.title, data.preview_text)
			: renderContent(data.public_title, data.public_preview_text);


	const renderMedia = (videoUrl, photoUrl) => (
		videoUrl ? (
			<a onClick={() => {handleActiveTabChange();}}
			   href={redirectLink(spaceUsername, data)} target="_self"
			>
				<ReactPlayer
					playing
					loop
					controls={false}
					muted
					width="100%"
					height="100%"
					className="videoPreview"
					url={videoUrl}
				/>
			</a>
		) : (
			<a onClick={() => {handleActiveTabChange();}}
			   href={redirectLink(spaceUsername, data)} target="_self"
			>
				<img className="card" alt="Description of image" src={photoUrl || default_bg} />
			</a>
		)
	);

	const mediaContentView = !updatesViewingUserIsMember || data.visibility === 'public'
		? renderMedia(data.public_cover_video ?? data.cover_video, data.public_cover_photo_medium ?? data.cover_photo_medium)
		: renderMedia(data.cover_video, data.cover_photo_medium);

	useEffect(() => {
		const path = window.location.pathname;
		if (path && path.startsWith('/@') && path.length > 3) {
			let directory = path.replace('/@', '');
			const split = directory.split('/');
			const username = split[0];
			setSpaceUsername(username);
		}
	}, [spaceUsername, data, updatesViewingUserIsMember]);

	return (
		<article className={`cs-excerpt-update block-md stack-y`}>
			{data && data.id ? (
				<>
					<a>
						{/* Render Media Content of updates */}
						{mediaContentView}


						{/*{!user ? (*/}
						{/*	<>*/}
						{/*		{data.visibility === 'members' ? (*/}
						{/*			<>*/}
						{/*				{(data && data.public_cover_video) ?*/}
						{/*					<ReactPlayer*/}
						{/*						playing={true}*/}
						{/*						loop={true}*/}
						{/*						controls={false}*/}
						{/*						muted={true}*/}
						{/*						width="100%"*/}
						{/*						height="100%"*/}
						{/*						className="videoPreview"*/}
						{/*						url={data.public_cover_video}*/}
						{/*					/>*/}
						{/*					:*/}
						{/*					<img className="card " alt="Description of image" src={(data.public_cover_photo_medium ? data.public_cover_photo_medium : default_bg)} />*/}
						{/*				}*/}
						{/*			</>*/}
						{/*				) : (*/}
						{/*			<>*/}
						{/*				{(data && data.cover_video) ?*/}
						{/*					<ReactPlayer*/}
						{/*						playing={true}*/}
						{/*						loop={true}*/}
						{/*						controls={false}*/}
						{/*						muted={true}*/}
						{/*						width="100%"*/}
						{/*						height="100%"*/}
						{/*						className="videoPreview"*/}
						{/*						url={data.public_cover_video}*/}
						{/*					/>*/}
						{/*					:*/}
						{/*					<img className="card " alt="Description of image" src={(data.cover_photo_medium ? data.cover_photo_medium : default_bg)} />*/}
						{/*				}*/}
						{/*			</>*/}
						{/*		)}*/}
						{/*	</>*/}
						{/*) : (*/}
						{/*	<>*/}
						{/*		{data.visibility === 'members' ? (*/}
						{/*			<>*/}
						{/*				{updatesViewingUserIsMember ? (*/}
						{/*					<>*/}
						{/*						{(data && data.cover_video) ?*/}
						{/*							<ReactPlayer*/}
						{/*								playing={true}*/}
						{/*								loop={true}*/}
						{/*								controls={false}*/}
						{/*								muted={true}*/}
						{/*								width="100%"*/}
						{/*								height="100%"*/}
						{/*								className="videoPreview"*/}
						{/*								url={data.cover_video}*/}
						{/*							/>*/}
						{/*							:*/}
						{/*							<img className="card " alt="Description of image" src={(data.cover_photo_medium ? data.cover_photo_medium : default_bg)} />*/}
						{/*						}*/}
						{/*					</>*/}
						{/*				) : (*/}
						{/*					<>*/}
						{/*						{(data && data.public_cover_video) ?*/}
						{/*							<ReactPlayer*/}
						{/*								playing={true}*/}
						{/*								loop={true}*/}
						{/*								controls={false}*/}
						{/*								muted={true}*/}
						{/*								width="100%"*/}
						{/*								height="100%"*/}
						{/*								className="videoPreview"*/}
						{/*								url={data.public_cover_video}*/}
						{/*							/>*/}
						{/*							:*/}
						{/*							<img className="card " alt="Description of image" src={(data.public_cover_photo_medium ? data.public_cover_photo_medium : default_bg)} />*/}
						{/*						}*/}
						{/*					</>*/}
						{/*				)}*/}
						{/*			</>*/}
						{/*		) : (*/}
						{/*			<>*/}
						{/*				{(data && data.public_cover_video) ?*/}
						{/*					<ReactPlayer*/}
						{/*						playing={true}*/}
						{/*						loop={true}*/}
						{/*						controls={false}*/}
						{/*						muted={true}*/}
						{/*						width="100%"*/}
						{/*						height="100%"*/}
						{/*						className="videoPreview"*/}
						{/*						url={data.public_cover_video}*/}
						{/*					/>*/}
						{/*					:*/}
						{/*					<img className="card " alt="Description of image" src={(data.public_cover_photo_medium ? data.public_cover_photo_medium : default_bg)} />*/}
						{/*				}*/}
						{/*			</>*/}
						{/*		)}*/}
						{/*	</>*/}
						{/*)}*/}

					</a>

					<div className="cs-stack-subtitle-published stack-row ">
						<time>{moment(data.created_on).format('LL')}</time>
						{data.categories && data.categories.map((category, index) => (
							<a key={index} href="#" className="chip-gradient">{category.name}</a>
						))}
					</div>
					<hgroup>
						{/* Title and preview text view */}
						{titlePreviewTextView}
						{/*{!updatesViewingUserIsMember && data.visibility === `Members Only` ? (*/}
						{/*	<>*/}
						{/*		<h4>*/}
						{/*			<a href="#" target="_self">*/}
						{/*				{((data.public_title && data.public_title !== '') ? data.public_title : <br></br>)}*/}
						{/*			</a>*/}
						{/*		</h4>*/}
						{/*		<p>*/}
						{/*			{(data && data.public_preview_text && data.public_preview_text !== '') && (*/}
						{/*				parse(data.public_preview_text)*/}
						{/*			)}*/}
						{/*		</p>*/}
						{/*	</>*/}
						{/*) : (*/}
						{/*	<>*/}
						{/*		<h4>*/}
						{/*			<a href="#" target="_self">*/}
						{/*				{((data.title && data.title !== '') ? data.title : <br></br>)}*/}
						{/*			</a>*/}
						{/*		</h4>*/}
						{/*		<p>*/}
						{/*			{ (data && data.preview_text && data.preview_text !== '') && (*/}
						{/*				parse(data.preview_text)*/}
						{/*			)}*/}
						{/*		</p>*/}
						{/*	</>*/}
						{/*)}*/}

						{/*{!user ? (*/}
						{/*	<>*/}
						{/*		{data.visibility === 'members' ? (*/}
						{/*			<>*/}
						{/*				<h4>*/}
						{/*					<a href="#" target="_self">*/}
						{/*						{((data.public_title && data.public_title !== '') ? data.public_title : <br></br>)}*/}
						{/*					</a>*/}
						{/*				</h4>*/}
						{/*				<p>*/}
						{/*					{ (data && data.public_preview_text && data.public_preview_text !== '') && (*/}
						{/*						parse(data.public_preview_text)*/}
						{/*					)}*/}
						{/*				</p>*/}
						{/*			</>*/}
						{/*		) : (*/}
						{/*			<>*/}
						{/*				<h4>*/}
						{/*					<a href="#" target="_self">*/}
						{/*						{((data.public_title && data.public_title !== '') ? data.public_title : <br></br>)}*/}
						{/*					</a>*/}
						{/*				</h4>*/}
						{/*				<p>*/}
						{/*					{ (data && data.public_preview_text && data.public_preview_text !== '') && (*/}
						{/*						parse(data.public_preview_text)*/}
						{/*					)}*/}
						{/*				</p>*/}
						{/*			</>*/}
						{/*		)}*/}
						{/*	</>*/}
						{/*) : (*/}
						{/*	<>*/}
						{/*		{data.visibility === 'members' ? (*/}
						{/*			<>*/}
						{/*				{updatesViewingUserIsMember ? (*/}
						{/*					<>*/}
						{/*						<h4>*/}
						{/*							<a href="#" target="_self">*/}
						{/*								{((data.title && data.title !== '') ? data.title : <br></br>)}*/}
						{/*							</a>*/}
						{/*						</h4>*/}
						{/*						<p>*/}
						{/*							{ (data && data.preview_text && data.preview_text !== '') && (*/}
						{/*								parse(data.preview_text)*/}
						{/*							)}*/}
						{/*						</p>*/}
						{/*					</>*/}
						{/*				) : (*/}
						{/*					<>*/}
						{/*						<h4>*/}
						{/*							<a href="#" target="_self">*/}
						{/*								{((data.public_title && data.public_title !== '') ? data.public_title : <br></br>)}*/}
						{/*							</a>*/}
						{/*						</h4>*/}
						{/*						<p>*/}
						{/*							{ (data && data.public_preview_text && data.public_preview_text !== '') && (*/}
						{/*								parse(data.public_preview_text)*/}
						{/*							)}*/}
						{/*						</p>*/}
						{/*					</>*/}
						{/*				)}*/}
						{/*			</>*/}
						{/*		) : (*/}
						{/*			<>*/}
						{/*				<h4>*/}
						{/*					<a href="#" target="_self">*/}
						{/*						{((data.title && data.title !== '') ? data.title : <br></br>)}*/}
						{/*					</a>*/}
						{/*				</h4>*/}
						{/*				<p>*/}
						{/*					{ (data && data.preview_text && data.preview_text !== '') && (*/}
						{/*						parse(data.preview_text)*/}
						{/*					)}*/}
						{/*				</p>*/}
						{/*			</>*/}
						{/*		)}*/}
						{/*	</>*/}
						{/*)}*/}
					</hgroup>
					<div className="cs-stack-fill ">
						{!user ? (
							<a className="button-secondary-gradient" href={routes.login()}>
								<span>
									<p>
										<i className="fa-regular fa-lock"/>
										Login to Unlock
									</p>
								</span>
							</a>
						) : (
							<>
								{data.visibility === 'members' ? (
									<>
										{updatesViewingUserIsMember ? (
											<a
												className="button-secondary-gradient"
												onClick={() => {
													handleActiveTabChange();
												}}
												href={redirectLink(spaceUsername, data)}
											>
												<span>
													<p>
														Read More
													</p>
												</span>
											</a>
										) : (
											<a
												className="button-secondary-gradient"
												onClick={() => {
													handleActiveTabChange();
												}}
												href={redirectLink(spaceUsername, data)}
											>
												<span>
													<p>
														 Read More
													</p>
												</span>
											</a>
										)}
									</>
								) : (
									<a
										className="button-secondary-gradient"
										onClick={() => {
											handleActiveTabChange();
										}}
										href={redirectLink(spaceUsername, data)}
									>
										<span>
											<p>
												Read More
											</p>
										</span>
									</a>
								)}
							</>
						)}
					</div>
				</>
				) : (
					<>
						{spaceData && spaceData.viewing_user_is_owner && (
							<div className="card-updates space-placeholder flex items-center justify-center">
								<h6 className="header-title text-center">
									Create Latest Updates <br />
									from your Dashboard
								</h6>
							</div>
						)}
					</>
				)
			}
		</article>
	);
};

export default memo(LatestUpdates);
