import React, {forwardRef, memo, useEffect, useRef, useState} from 'react';
import { Link } from 'react-router-dom';
import co_logo from '../../../../assets/images/co_logo.svg';
import { getSitewideLink } from '../../../../utils/sitewideLinks';
import Alert from 'react-bootstrap/Alert';
import routes from '../../../../routes';
import DropdownWithItems from '../../../../components/commons/DropdownWithItems';
import { LANGUAGE, LOCATION } from '../../../../constants/profile';
import useIsInViewport from "../../../../utils/checkPageScrollPosition";
import {useDispatch, useSelector} from "react-redux";
import {setIsFooterInViewPort} from "../../../../states/footerInViewSlice";
import ReactPlayer from "react-player/lazy";
import default_user_profile from "../../../../assets/images/default_user_profile.png";
import SocialLinks from "../../../../components/commons/SocialLinks";

const FooterProV2 = (props) => {
    const { spaceData, activeTab, onSelectedTabChange, isSpaceLogin } = props;
    const { authUserInfo } = useSelector((state) => state.general);
	const pro_styles = spaceData && spaceData?.pro_style ? spaceData?.pro_style : null;
    const languageList = LANGUAGE;
	const locationList = LOCATION;
    const [selectedLanguage, setSelectedLanguage] = useState('English');
	const [selectedLocation, setSelectedLocation] = useState('United States');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
	const ref = useRef(null);
	const isInViewport = useIsInViewport(ref);
	const dispatch = useDispatch();
    const tabs = ['home', 'updates', 'benefits', 'members'];

    const header_navs = [];
    if (spaceData && spaceData?.pro_navigations) {
        spaceData?.pro_navigations.forEach((item) => {
            if (item.section === 'header') {
                header_navs.push(item);
            }
        });
    }

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        // document.body.classList.toggle('lock-body');
    };

	useEffect(() => {
		dispatch(setIsFooterInViewPort(isInViewport));
	}, [isInViewport]);

	let left_logo = co_logo;
	if ((spaceData && spaceData?.plan === 'pro') || (spaceData && isSpaceLogin)) {
		left_logo = spaceData && spaceData?.photo_thumb ? spaceData?.photo_thumb : co_logo;
		left_logo = pro_styles && pro_styles.logo ? pro_styles.logo : left_logo;
	}

	const footer_navs = [];
	if (spaceData && spaceData?.pro_navigations) {
		spaceData?.pro_navigations.forEach((item) => {
			if (item.section === 'footer') {
				footer_navs.push(item);
			}
		});
	}

	const setActiveTabChange = (item) => {
		onSelectedTabChange(item);
	};

	useEffect(() => {}, [spaceData, activeTab]);

    const getProLogo = () => {
        // Main = logo
        // Alt = altThemeLogoVideo
        // header = header_background_image
        // footer = header_background_video
        let main = pro_styles?.logo;
        let alt = pro_styles?.altThemeLogoVideo;
        let header = pro_styles?.header_background_image;
        let footer = pro_styles?.header_background_video;
        let selected_theme = pro_styles?.home_theme;
        if(location.pathname.includes('update')){
            selected_theme = pro_styles?.updates_theme;
        }else if(location.pathname.includes('benefit')){
            selected_theme = pro_styles?.benefits_theme;
        }else if(location.pathname.includes('member')){
            selected_theme = pro_styles?.members_theme;
        }
        
        if(pro_styles){
            if(footer){
                return footer;
            }
            else if(alt){
                if(selected_theme === 'main'){
                    return main;
                }else{
                    return alt;
                }
            }
            else if(main){
                if(selected_theme === 'main'){
                    return main;
                }else{
                    return alt;
                }
            }else{
                return co_logo;
            }
        }else{
            return co_logo;
        }
    }

    return (
        <footer id="cs-space-footer" className={`cs-footer ${pro_styles ? pro_styles?.footer_theme === 'main' ? 'theme' : 'theme-alt' : 'theme' }  pt-0 pb-0 sm:pb-section-safeview px-0 sm:px-section-safeview`}>
            {/* SECTION - Footer Frame */}
            <div id="footer-frame"
                 className={`w-full centerframe-grid max-w-breakpoint py-gutter-2xl sm:pt-section sm:pb-0 px-section-safeview-base sm:px-0 ${isMenuOpen ? '' : 'hide'}`}
            >
                <div className="cs-footer-brand block-base">
                    <div className="stack-col">

                        <div id="cs-space-footer-logo" className="block-logo mobile-none-flex">
                            <Link
                                to={routes.viewUserSpace() + spaceData?.username}
                                onClick={(event) => setActiveTabChange('home')}
                            >
                                <img alt="home" src={getProLogo()} />
                            </Link>
                        </div>

                        {/*<p className="cs-footer-brand-text  mb-4">*/}
                        {/*    {spaceData && spaceData?.about && (*/}
                        {/*        <div*/}
                        {/*            className="footer-about"*/}
                        {/*            dangerouslySetInnerHTML={{ __html: spaceData?.about }}*/}
                        {/*        ></div>*/}
                        {/*    )}</p>*/}

                        {/* /ANCHOR - Space Short Description */}
                        {/* CSJS Content based on Space Admin entered values. Defaults to {space_shortdesc}. Learn more link is added automatically, linking to the about page.  */}
                        <div className="cs-footer-brand-text mobile-none-block">
                            <p>{spaceData?.about}{' '}
                                <a className="pr-1" href={spaceData?.username ? `/@${spaceData?.username}/about` : ''} target="_blank">Learn more</a>
                            </p>
                        </div>

                        {/* CSJS Links based on Space Admin entered values {space_social_*_*}. */}
                        {(spaceData && spaceData?.social_links) && (
                            <SocialLinks
                                socialLinks={spaceData.social_links}
                                parentClassName="cs-stack-link-icons stack-row mobile-2"
                                childClassName="button-icon-round-gradient"
                            />
                        )}
                        {/*<div className="cs-stack-link-icons stack-row mobile-2">*/}
                        {/*    <div className='text-center'>*/}
                        {/*        {getSitewideLink('footer_twitter_icon', "button-icon-round-gradient", true)}*/}
                        {/*    </div>*/}
                        {/*    <div  className='text-center'>*/}
                        {/*        {getSitewideLink('footer_instagram_icon', "button-icon-round-gradient", true)}*/}
                        {/*    </div>*/}
                        {/*    <div  className='text-center'>*/}
                        {/*        {getSitewideLink('footer_facebook_icon', "button-icon-round-gradient", true)}*/}
                        {/*    </div>*/}
                        {/*    <div className='text-center' >*/}
                        {/*        {getSitewideLink('footer_youtube_icon', "button-icon-round-gradient", true)}*/}
                        {/*    </div>*/}
                        {/*    <div className='text-center' >*/}
                        {/*        {getSitewideLink('footer_tiktok_icon', "button-icon-round-gradient", true)}*/}
                        {/*    </div>*/}
                        {/*    <div className='text-center' >*/}
                        {/*        {getSitewideLink('footer_discord_icon', "button-icon-round-gradient", true)}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="cs-footer-localization stack-row-wrap mobile-1">
                            <button type="button"
                                    className="icon-dropdown-gradient-alt"
                                    id="menu-button-country"
                                    aria-expanded="false"
                                    aria-haspopup="true">
                                    <span>
                                        <i className="fa-light fa-globe"/>
                                        <p>United States</p>
                                        <i className="fa-solid fa-caret-down"/>
                                    </span>
                            </button>

                            <button
                                type="button"
                                className="icon-dropdown-gradient-alt"
                                id="menu-button-language"
                                aria-expanded="false"
                                aria-haspopup="true">
                                <span><i className="fa-light fa-language"></i><p>English</p><i className="fa-solid fa-caret-down"></i></span>
                            </button>
                            {/*<DropdownWithItems*/}
                            {/*    title="Location"*/}
                            {/*    customButtonClass="btn-md btn-secondary w-full xl:w-[200px] "*/}
                            {/*    icon="fa-regular fa-globe"*/}
                            {/*    selectedValue={selectedLocation}*/}
                            {/*    listItems={locationList}*/}
                            {/*    onDropdownChange={setSelectedLocation}*/}
                            {/*/>*/}
                            {/*<DropdownWithItems*/}
                            {/*    title="Language"*/}
                            {/*    customButtonClass="btn-md btn-secondary w-full xl:w-[200px]"*/}
                            {/*    icon="fa-regular fa-language"*/}
                            {/*    selectedValue={selectedLanguage}*/}
                            {/*    listItems={languageList}*/}
                            {/*    onDropdownChange={setSelectedLanguage}*/}
                            {/*/>*/}
                        </div>
                    </div>
                </div>
                {/*Spacer Block*/}
                <div className="cs-footer-spacer mobile-none-block"/>

                <div id="cs-space-footer-nav-1" className="block-base mobile-none-block">
                    <nav className="cs-footer-nav-stack stack-col">
                        <h3>Community</h3>
                        <div className="cs-footer-nav-stack-links">
                            <p>
                                <Link
                                    to={routes.viewUserSpace() + spaceData?.username}
                                    onClick={(event) => setActiveTabChange('home')}
                                >
                                    Home
                                </Link>
                                {/*{getSitewideLink('footer_pro_community_home', ' ')}*/}
                            </p>
                            <p>
                                <Link
                                    to={
                                        routes.viewUserSpace() + spaceData?.username + '/update'
                                    }
                                    onClick={(event) => setActiveTabChange('updates')}
                                >
                                    Updates
                                </Link>
                                {/*{getSitewideLink('footer_pro_community_updates', ' ')}*/}
                            </p>
                            <p>
                                <Link
                                    to={
                                        routes.viewUserSpace() +
                                        spaceData?.username +
                                        '/benefit'
                                    }
                                    onClick={(event) => setActiveTabChange('benefits')}
                                >
                                    Benefits
                                </Link>
                                {/*{getSitewideLink('footer_pro_community_benefits', ' ')}*/}
                            </p>
                            <p>
                                <Link
                                    to={
                                        routes.viewUserSpace() + spaceData?.username + '/member'
                                    }
                                    onClick={(event) => setActiveTabChange('members')}
                                >
                                    Members
                                </Link>
                                {/*{getSitewideLink('footer_pro_community_members', ' ')}*/}
                            </p>
                            <p>
                                <Link
                                    to={
                                        routes.viewUserSpace() + spaceData?.username + '/about'
                                    }
                                    onClick={(event) => setActiveTabChange('about')}
                                >
                                    About
                                </Link>
                                {/*{getSitewideLink('footer_pro_community_members', ' ')}*/}
                            </p>
                            {/*<p>*/}
                            {/*    <a*/}
                            {/*        href={*/}
                            {/*            routes.viewUserSpace() +*/}
                            {/*            spaceData?.username +*/}
                            {/*            '/privacy-policy'*/}
                            {/*        }*/}
                            {/*    >*/}
                            {/*        Privacy Policy*/}
                            {/*    </a>*/}
                            {/*    /!*<Link to="#">Privacy Policy</Link>*!/*/}
                            {/*    /!*{getSitewideLink('footer_pro_community_privacy_policy', ' ')}*!/*/}
                            {/*</p>*/}
                            {/*<p>*/}
                            {/*    <a*/}
                            {/*        href={*/}
                            {/*            routes.viewUserSpace() +*/}
                            {/*            spaceData?.username +*/}
                            {/*            '/terms-services'*/}
                            {/*        }*/}
                            {/*    >*/}
                            {/*        Terms of Use*/}
                            {/*    </a>*/}
                            {/*    /!*<Link to="#">Terms of Use</Link>*!/*/}
                            {/*    /!*{getSitewideLink('footer_pro_community_terms_of_use', ' ')}*!/*/}
                            {/*</p>*/}
                        </div>
                    </nav>
                </div>

                <div id="cs-space-footer-nav-2" className="block-base mobile-none-block">
                    <nav className="cs-footer-nav-stack stack-col">
                        <h3 className="list-heading ">
                             {spaceData && spaceData?.pro_style?.navigation_list_title
		 									? spaceData?.pro_style?.navigation_list_title
		 									: 'Resources'}
                         </h3>
                        <div className="cs-footer-nav-stack-links">
                            {footer_navs.map((data, index) => (
                                <p key={index}>
                                    <a
                                        href={data.url}
                                        target={
                                            data.open_type === 'open_new_window'
                                                ? '_blank'
                                                : ''
                                        }
                                    >
                                        {data.label}
                                    </a>
                                </p>
                            ))}
                        </div>
                    </nav>
                </div>

                <div id="cs-space-footer-nav-3" className="block-base mobile-none-block">
                    <nav className="cs-footer-nav-stack stack-col">
                        <h3>Help</h3>
                        <div className="cs-footer-nav-stack-links">
                            <p>
                                <a href={routes.viewUserSpace() + spaceData?.username + '/faq'}>
                                    FAQ
                                </a>

                            </p>
                            <p>
                                <a
                                    href={
                                        routes.viewUserSpace() +
                                        spaceData?.username +
                                        '/support'
                                    }
                                >
                                    Support
                                </a>

                            </p>
                            <p>
                                <a
                                    href={
                                        routes.viewUserSpace() + spaceData?.username + '/contact'
                                    }
                                >
                                    Contacts
                                </a>

                            </p>
                            <p>
                                <a
                                    href={
                                        routes.viewUserSpace() +
                                        spaceData?.username +
                                        '/privacy-policy'
                                    }
                                >
                                    Privacy
                                </a>

                            </p>
                            <p>
                                <a
                                    href={
                                        routes.viewUserSpace() +
                                        spaceData?.username +
                                        '/terms-services'
                                    }
                                >
                                    Terms
                                </a>

                            </p>
                        </div>
                    </nav>
                </div>

                {/*//ANCHOR - Copyright*/}
                <div className="cs-footer-copyright block-full mobile-none-block">
                    <p>© {spaceData ? spaceData?.name : ''}. All Rights Reserved.</p>
                </div>

                {/*//ANCHOR - Footer Mobile Menu Links*/}
                <div id="cs-space-footer-mobile-menu-links" className="block-full mobile-only-block">
                    {/* CSJS Links based on Space Admin entered values {space_footer_nav*_title}, {space_footer_nav*_link*_text}, {space_footer_nav*_link*_target}, and {space_footer_nav*_link*_url}.*/}
                    <nav>
                        {tabs.map((tab, index) => (
                            <p key={index}>
                                <a
                                    onClick={() => setActiveTabChange(tab)}
                                >
                                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                                </a>
                            </p>
                        ))}
                        {header_navs.map((data, index) => (
                            <p key={index}>
                                <a href={data.url}
                                   target={data.open_type === 'open_new_window' ? '_blank' : '_self'}
                                >
                                    {data.label}
                                </a>
                            </p>
                        ))}
                    </nav>
                </div>

                {/*//ANCHOR - PUBLIC User Auth Button Links*/}
                {/*CSJS Visible/exists ONLY for PUBLIC. If present, className="!hidden" (specifically with the "!") is used to demo visibility based on the demo state, it is not part of the normal usage.*/}
                {!authUserInfo && (
                    <div id="cs-space-footer-mobile-auth" className="block-full mobile-only-block">
                        <nav>
                            <a href={routes.login()} target="_self" className="button-secondary-gradient"><span><p>Login</p></span></a>
                            <a href={routes.signUp()} target="_self" className="button-primary-gradient"><span><p>Sign Up</p></span></a>
                        </nav>
                    </div>
                )}

            </div>

            {/*SECTION MEMBER User Menu Menu*/}
            {/*/!*CSJS Current toggle is a simple implementation for demo only.*!/*/}
            {/*<div id="cs-space-user-menu" className={`block-base mr-section-safeview ${isMenuOpen ? '' : 'hide'}`}>*/}
            {/*    <nav>*/}
            {/*        <a href="fam-profile-dashboard.html" target="_self">*/}
            {/*            <div>*/}
            {/*                <span><i className="fa-light fa-chart-tree-map"></i></span>*/}
            {/*                <p>Dashboard</p>*/}
            {/*            </div>*/}
            {/*        </a>*/}
            {/*        <a href="fam-profile-activity.html" target="_self">*/}
            {/*            <div>*/}
            {/*                <span><i className="fa-light fa-list-ul"></i></span>*/}
            {/*                <p>Activity</p>*/}
            {/*            </div>*/}
            {/*        </a>*/}
            {/*        <a href="fam-profile-spaces.html" target="_self">*/}
            {/*            <div>*/}
            {/*                <span><i className="fa-light fa-solar-system"></i></span>*/}
            {/*                <p>Spaces</p>*/}
            {/*            </div>*/}
            {/*        </a>*/}
            {/*        <a href="fam-profile-collections.html" target="_self">*/}
            {/*            <div>*/}
            {/*                <span><i className="fa-light fa-grid"></i></span>*/}
            {/*                <p>Collections</p>*/}
            {/*            </div>*/}
            {/*        </a>*/}
            {/*        <a href="fam-profile-profile-self.html" target="_self">*/}
            {/*            <div>*/}
            {/*                <span><i className="fa-light fa-id-badge"></i></span>*/}
            {/*                <p>Profile</p>*/}
            {/*            </div>*/}
            {/*        </a>*/}
            {/*        <a href="fam-profile-account.html" target="_self">*/}
            {/*            <div>*/}
            {/*                <span><i className="fa-light fa-gear"></i></span>*/}
            {/*                <p>Account</p>*/}
            {/*            </div>*/}
            {/*        </a>*/}
            {/*        <a href="fam-profile-logout.html" target="_self">*/}
            {/*            <div>*/}
            {/*                <span><i className="fa-light fa-arrow-right-from-bracket"></i></span>*/}
            {/*                <p>Log Out</p>*/}
            {/*            </div>*/}
            {/*        </a>*/}
            {/*    </nav>*/}
            {/*</div>*/}
            {/* !SECTION MEMBER Mobile Menu */}

            {/* SECTION FOOTER MOBILE NAV & TOGGLES */}
            {/* CSJS MOBILE NAV is used for all User States (PUBLIC/MEMBER) with specific icon buttons only visible/exists if noted for the its User State. If present, the !hidden class (specifically with the "!") is used to demo visibility based on the demo state, it is not part of the normal usage.  */}
            <div id="cs-space-footer-mobile-nav" className="block-full mobile-only-block px-section-safeview">
                <nav>
                    {/*CSJS Visible/exists for both PUBLIC and MEMBER*/}
                    <a href="/">
                        <i className="fa-regular fa-solar-system"/>
                    </a>
                    {/*CSJS Visible/exists ONLY for MEMBER. If present, className="!hidden" (specifically with the "!") is used to demo visibility based on the demo state, it is not part of the normal usage.*/}
                    {/*{authUserInfo && (*/}
                    {/*    <a*/}
                    {/*        // href="fam-profile-notifications.html"*/}
                    {/*    >*/}
                    {/*        <i className="fa-regular fa-bell"/>*/}
                    {/*    </a>*/}
                    {/*)}*/}
                    {/*CSJS Visible/exists ONLY for MEMBER. Toggle "active" class on menu display. If present, className="!hidden" (specifically with the "!") is used to demo visibility based on the demo state, it is not part of the normal usage.*/}
                    {/*{authUserInfo && (*/}
                    {/*    <a id="mobile-user-menu-toggle" className={`avatar-gradient`}>*/}
                    {/*        { authUserInfo.profile.video ? (*/}
                    {/*            <ReactPlayer*/}
                    {/*                playing={true}*/}
                    {/*                loop={true}*/}
                    {/*                muted={true}*/}
                    {/*                controls={false}*/}
                    {/*                width="100%"*/}
                    {/*                height="100%"*/}
                    {/*                className={`videoPreview`}*/}
                    {/*                url={authUserInfo.profile.video}*/}
                    {/*            />*/}
                    {/*        ) : (*/}
                    {/*            <img*/}
                    {/*                src={authUserInfo && authUserInfo?.profile?.photo_thumb ? authUserInfo?.profile?.photo_thumb : default_user_profile}*/}
                    {/*                alt="Image of cs_profile_username"*/}
                    {/*            />*/}
                    {/*        )}*/}
                    {/*    </a>*/}
                    {/*)}*/}
                    {/*CSJS Visible/exists ONLY for MEMBER. If present, className="!hidden" (specifically with the "!") is used to demo visibility based on the demo state, it is not part of the normal usage.*/}
                    {/*{authUserInfo && (*/}
                    {/*    <a*/}
                    {/*        // href="fam-profile-messaging.html"*/}
                    {/*    >*/}
                    {/*        <i className="fa-regular fa-comments"/>*/}
                    {/*    </a>*/}
                    {/*)}*/}

                    {/*CSJS Visible/exists for both PUBLIC and MEMBER*/}
                    <a onClick={toggleMenu} id="mobile-footer-menu-toggle" className="toggle-menu-gradient">
                        <i id="mobile-footer-menu-open" className={`fa-regular fa-bars ${isMenuOpen ? 'hide' : ''}`}/>
                        {/*CSJS Toggle "hide" class on menu display. The "active" class can be kept as always present.*/}
                        <i id="mobile-footer-menu-close" className={`fa-regular fa-angles-down active ${isMenuOpen ? '' : 'hide'}`}/>
                    </a>
                    {/*CSJS Visible/exists ONLY for PUBLIC. If present, className="!hidden" (specifically with the "!") is used to demo visibility based on the demo state, it is not part of the normal usage.*/}
                    {authUserInfo &&
                        <a href={routes.logout()}>
                            <i className="fa-regular fa-right-to-bracket"/>
                        </a>
                    }
                </nav>
            </div>
            {/*!SECTION FOOTER MOBILE NAV & TOGGLES*/}
        </footer>
    )
}
export default memo(FooterProV2);