import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import Alert from 'react-bootstrap/Alert';

import { VALIDATE_MESSAGES } from '../../../../constants/common';
import UploadDragger from '../../../../components/commons/UploadDragger';
import {
	IMAGE_FILETYPES,
	IMAGE_LARGE_FILE_UPLOAD_SIZE,
	IMAGE_LARGE_MAX_HEIGHT,
	IMAGE_LARGE_MAX_WIDTH,
	IMAGE_LARGE_MIN_HEIGHT,
	IMAGE_LARGE_MIN_WIDTH,
	IMAGE_STANDARD_FILE_UPLOAD_SIZE,
	IMAGE_STANDARD_MAX_HEIGHT,
	IMAGE_STANDARD_MAX_WIDTH,
	IMAGE_STANDARD_MIN_HEIGHT,
	IMAGE_STANDARD_MIN_WIDTH,
	VIDEO_FILETYPES,
	VIDEO_STANDARD_FILE_UPLOAD_SIZE,
	VIDEO_STANDARD_MAX_HEIGHT,
	VIDEO_STANDARD_MAX_WIDTH,
	VIDEO_STANDARD_MIN_HEIGHT,
	VIDEO_STANDARD_MIN_WIDTH
} from '../../../../constants/file';
import {
	ACCESS_TYPES_LIST_WITH_DEFAULT,
	CONTENT_TYPES_LIST_WITH_DEFAULT
} from '../../../../constants/space';
import DropdownWithItems from '../../../../components/commons/DropdownWithItems';
import { getListIcon } from '../../../../utils/list';
import TextareaAutosize from 'react-textarea-autosize';
import { CUSTOM_REGEX } from '../../../../constants/custom_regex';
import { showTooltip } from '../../../../utils/input';
import { checkShortCodeAvailability, createSpace } from '../../../../services/space';
import { checkUsernameAvailability } from '../../../../services/general';
import { useDispatch } from 'react-redux';
import {
	displayPageContentLoader,
	hidePageContentLoader
} from '../../../../states/pageContentLoader';
import { getSpaceInfo } from '../../../../states/spaceGlobal/actions';
import {getSitewideLink} from "../../../../utils/sitewideLinks";
import UploadDraggerV2 from "../../../../components/commons/UploadDraggerV2";
import ExplicitContent from "../../../../components/commons/Modals/ExplicitContent";
import FormFieldStacked from "../../../../components/commons/FormFieldStacked";

const ManageProfile = (props) => {
	const { authUserInfo, spaceInfo } = props;
	const dispatch = useDispatch();
	const [manageSpaceProfileForm] = Form.useForm();
	const [alert, setAlert] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [username, setUsername] = useState(null);
	const [profileImageError, setProfileImageError] = useState(null);
	const [backgroundImageError, setBackgroundImageError] = useState(null);
	const [profileImage, setProfileImage] = useState(null);
	const [profileImageBlobPreview, setProfileImageBlobPreview] = useState(null);
	const [profileVideo, setProfileVideo] = useState(null);
	const [profileVideoBlobPreview, setProfileVideoBlobPreview] = useState(null);
	const [backgroundImage, setBackgroundImage] = useState(null);
	const [backgroundImageBlobPreview, setBackgroundImageBlobPreview] = useState(null);
	const [backgroundVideo, setBackgroundVideo] = useState(null);
	const [backgroundVideoBlobPreview, setBackgroundVideoBlobPreview] = useState(null);
	const [selectedContentType, setSelectedContentType] = useState('has_no_explicit');
	const [selectedAccessType, setSelectedAccessType] = useState('has_no_explicit');
	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const [photoIsRemoved, setPhotoIsRemoved] = useState(false);
	const [videoIsRemoved, setVideoIsRemoved] = useState(false);
	const [photoBGIsRemoved, setPhotoBGIsRemoved] = useState(false);
	const [videoBGIsRemoved, setVideoBGIsRemoved] = useState(false);
	const [profileVideoError, setProfileVideoError] = useState(null);
	const [backgroundVideoError, setBackgroundVideoError] = useState(null);
	const [displayName, setDisplayName] = useState(null);
	const [spaceShortCode, setSpaceShortCode] = useState(null);
	const [location, setLocation] = useState(null);
	const [about, setAbout] = useState(null);
	const [website, setWebsite] = useState(null);
	const [instagram, setInstagram] = useState(null);
	const [youtube, setYoutube] = useState(null);
	const [tiktok, setTiktok] = useState(null);
	const [twitch, setTwitch] = useState(null);
	const [facebook, setFacebook] = useState(null);
	const [linkedin, setLinkedin] = useState(null);
	const [x, setX] = useState(null);
	const [modalOpenExplicitContent, setModalOpenExplicitContent] = useState(false);
	const [modalOpenExplicitAccess, setModalOpenExplicitAccess] = useState(false);
	const [selectedExplicitContent, setSelectedExplicitContent] = useState([]);
	const [selectedExplicitAccess, setSelectedExplicitAccess] = useState([]);

	const handleFormOnBlur = useCallback(async () => {
		await manageSpaceProfileForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleFormChanges = useCallback(async () => {
		setDisplayName(manageSpaceProfileForm.getFieldValue('display_name'));
		setUsername(manageSpaceProfileForm.getFieldValue('username'));
		setSpaceShortCode(manageSpaceProfileForm.getFieldValue('shortcode'));
		setLocation(manageSpaceProfileForm.getFieldValue('location'));
		setWebsite(manageSpaceProfileForm.getFieldValue('website'));
		setAbout(manageSpaceProfileForm.getFieldValue('about'));
		setInstagram(manageSpaceProfileForm.getFieldValue('instagram'));
		setYoutube(manageSpaceProfileForm.getFieldValue('youtube'));
		setTiktok(manageSpaceProfileForm.getFieldValue('tiktok'));
		setTwitch(manageSpaceProfileForm.getFieldValue('twitch'));
		setFacebook(manageSpaceProfileForm.getFieldValue('facebook'));
		setLinkedin(manageSpaceProfileForm.getFieldValue('linkedin'));
		setX(manageSpaceProfileForm.getFieldValue('x'));

		await manageSpaceProfileForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const websiteOnBlur = (event) => {
		showTooltip(event);
		if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(event.target.value)) {
			event.target.value = 'https://' + event.target.value;
			manageSpaceProfileForm.setFieldValue('website', event.target.value);
		}
	};

	const handleSubmit = useCallback(
		async (values) => {
			setDisplayDropDownError(true);
			setAlert(null);
			setProfileImageError(null);
			setBackgroundImageError(null);

			let hasError = false;

			if (selectedContentType === 'default' || selectedAccessType === 'default') {
				hasError = true;
			}

			if (!profileImageBlobPreview) {
				setProfileImageError('Space Profile Image is required.');
				hasError = true;
			}

			if (!backgroundImageBlobPreview) {
				setBackgroundImageError('Space Profile Background Image is required.');
				hasError = true;
			}

			if (hasError) {
				window.scrollTo(0, 0);
				return;
			}

			const data = values;

			//include url protocol when url protocol is missing
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.website)) {
				data.website = 'https://' + data.website;
			}
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.instagram)) {
				data.instagram = 'https://' + data.instagram;
			}
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.youtube)) {
				data.youtube = 'https://' + data.youtube;
			}
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.tiktok)) {
				data.tiktok = 'https://' + data.tiktok;
			}
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.twitch)) {
				data.twitch = 'https://' + data.twitch;
			}
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.facebook)) {
				data.facebook = 'https://' + data.facebook;
			}
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.linkedin)) {
				data.linkedin = 'https://' + data.linkedin;
			}
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.x)) {
				data.x = 'https://' + data.x;
			}

			data.content_type = selectedContentType;
			data.content_access = selectedAccessType;
			data.explicit_content = JSON.stringify(selectedExplicitContent);
			data.explicit_access = JSON.stringify(selectedExplicitAccess);

			if (profileImage) {
				data.photo = profileImage;
			} else {
				if (photoIsRemoved) {
					data.photo_is_empty = true;
				}
			}

			if (profileVideo) {
				data.video = profileVideo;
			} else {
				if (videoIsRemoved) {
					data.video_is_empty = true;
				}
			}

			if (backgroundImage) {
				data.background_photo = backgroundImage;
			} else {
				if (photoBGIsRemoved) {
					data.background_photo_is_empty = true;
				}
			}

			if (backgroundVideo) {
				data.background_video = backgroundVideo;
			} else {
				if (videoBGIsRemoved) {
					data.background_video_is_empty = true;
				}
			}

			if (data.about === '') {
				data.about = null;
			}

			try {
				if (authUserInfo) {
					setSubmitted(true);
					data.space_id = spaceInfo.id;
					data.user_id = authUserInfo.id;

					const response = await createSpace(data);
					if (response && response.result) {
						setAlert({
							variant: 'success',
							message: response.message
						});
						dispatch(getSpaceInfo(spaceInfo.username));
					} else {
						setAlert({
							variant: 'danger',
							message: response.message
						});
					}
					setPhotoIsRemoved(false);
					setVideoIsRemoved(false);
					setPhotoBGIsRemoved(false);
					setVideoBGIsRemoved(false);
					setSubmitted(false);
					setDisplayDropDownError(false);
					setTimeout(() => {
						setAlert(null);
					}, 3000);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[
			authUserInfo,
			spaceInfo,
			profileImage,
			profileVideo,
			backgroundImage,
			backgroundVideo,
			selectedContentType,
			selectedAccessType,
			selectedExplicitAccess,
			selectedExplicitContent,
			photoIsRemoved,
			videoIsRemoved,
			photoBGIsRemoved,
			videoBGIsRemoved,
			profileImageBlobPreview,
			backgroundImageBlobPreview
		]
	);

	const handleProfileImageUploadFileChange = (file, blobPreview, error = false) => {
		if (!file) {
			if(error){
				setProfileImageError('Space Profile Image is Invalid.');
			}
			setPhotoIsRemoved(true);

		} else {
			setPhotoIsRemoved(false);
			if(!error){
				setProfileImageError(null);
			}
		}
		setProfileImage(file);
		setProfileImageBlobPreview(blobPreview);
	};

	const handleProfileVideoUploadFileChange = (file, blobPreview,error = false) => {
		if (!file) {
			if(error){
				setProfileVideoError('Space Profile Video is Invalid.');
			}
			setVideoIsRemoved(true);
		} else {
			setVideoIsRemoved(false);
			if(!error){
				setProfileVideoError(null);
			}
		}
		setProfileVideo(file);
		setProfileVideoBlobPreview(blobPreview);
	};

	const handleBackgroundImageUploadFileChange = (file, blobPreview, error = false) => {
		if (!file) {
			if(error){
				setBackgroundImageError('Space Profile Background Image is Invalid.');
			}
			setPhotoBGIsRemoved(true);

		} else {
			setPhotoBGIsRemoved(false);
			if(!error){
				setBackgroundImageError(null);
			}
		}
		setBackgroundImage(file);
		setBackgroundImageBlobPreview(blobPreview);
	};

	const handleBackgroundVideoUploadFileChange = (file, blobPreview, error = false) => {
		if (!file) {
			if(error){
				setBackgroundVideoError('Space Background Video is Invalid.');
			}
			setVideoBGIsRemoved(true);
		} else {
			setVideoBGIsRemoved(false);
			if(!error){
				setBackgroundVideoError(null);
			}
		}
		setBackgroundVideo(file);
		setBackgroundVideoBlobPreview(blobPreview);
	};
	// const handleProfileImageUploadFileChange = (file, blobPreview) => {
	// 	if (!file) {
	// 		setPhotoIsRemoved(true);
	// 	} else {
	// 		setPhotoIsRemoved(false);
	// 	}
	//
	// 	setProfileImage(file);
	// 	setProfileImageBlobPreview(blobPreview);
	// };
	//
	// const handleProfileVideoUploadFileChange = (file, blobPreview) => {
	// 	if (!file) {
	// 		setVideoIsRemoved(true);
	// 	} else {
	// 		setVideoIsRemoved(false);
	// 	}
	//
	// 	setProfileVideo(file);
	// 	setProfileVideoBlobPreview(blobPreview);
	// };
	//
	// const handleBackgroundImageUploadFileChange = (file, blobPreview) => {
	// 	if (!file) {
	// 		setPhotoBGIsRemoved(true);
	// 	} else {
	// 		setPhotoBGIsRemoved(false);
	// 	}
	//
	// 	setBackgroundImage(file);
	// 	setBackgroundImageBlobPreview(blobPreview);
	// };
	//
	// const handleBackgroundVideoUploadFileChange = (file, blobPreview) => {
	// 	if (!file) {
	// 		setVideoBGIsRemoved(true);
	// 	} else {
	// 		setVideoBGIsRemoved(false);
	// 	}
	//
	// 	setBackgroundVideo(file);
	// 	setBackgroundVideoBlobPreview(blobPreview);
	// };

	const setSocialLink = (socialLinks, socialLinkType) => {
		let s_link = null
		socialLinks.map((socialLink)=>{
			if (socialLink.social_type === socialLinkType && socialLink.social_link && socialLink.social_link?.trim() !== null)
				s_link = socialLink.social_link
		})
		return s_link
	};

	const setFormSpaceData = useCallback(
		async (spaceData) => {
			let textAreaAbout = spaceData.about ? spaceData.about : '';
			manageSpaceProfileForm.setFieldsValue({
				display_name: spaceData.name,
				username: spaceData.username,
				shortcode: spaceData.shortcode,
				location: spaceData.location,
				about: textAreaAbout,
				website: spaceData.social_links ? setSocialLink(spaceData.social_links, 'website') : null,
				instagram: spaceData.social_links ? setSocialLink(spaceData.social_links, 'instagram') : null,
				youtube: spaceData.social_links ? setSocialLink(spaceData.social_links, 'youtube') : null,
				tiktok: spaceData.social_links ? setSocialLink(spaceData.social_links, 'tiktok') : null,
				twitch: spaceData.social_links ? setSocialLink(spaceData.social_links, 'twitch') : null,
				facebook: spaceData.social_links ? setSocialLink(spaceData.social_links, 'facebook') : null,
				linkedin: spaceData.social_links ? setSocialLink(spaceData.social_links, 'linkedin') : null,
				x: spaceData.social_links ? setSocialLink(spaceData.social_links, 'x') : null
			});
			setDisplayName(spaceData.name);
			setUsername(spaceData.username);
			setSpaceShortCode(spaceData.shortcode);
			setLocation(spaceData.location);
			setAbout(textAreaAbout);
			setWebsite(spaceData.social_links ? setSocialLink(spaceData.social_links, 'website') : null);
			setInstagram(spaceData.social_links ? setSocialLink(spaceData.social_links, 'instagram') : null);
			setYoutube(spaceData.social_links ? setSocialLink(spaceData.social_links, 'youtube') : null);
			setTiktok(spaceData.social_links ? setSocialLink(spaceData.social_links, 'tiktok') : null);
			setTwitch(spaceData.social_links ? setSocialLink(spaceData.social_links, 'twitch') : null);
			setFacebook(spaceData.social_links ? setSocialLink(spaceData.social_links, 'facebook') : null);
			setLinkedin(spaceData.social_links ? setSocialLink(spaceData.social_links, 'linkedin') : null);
			setX(spaceData.social_links ? setSocialLink(spaceData.social_links, 'x') : null);
			setSelectedExplicitContent(JSON.parse(spaceData.explicit_content));
			setSelectedExplicitAccess(JSON.parse(spaceData.explicit_access));
			setSelectedContentType(spaceData.content_type);
			setSelectedAccessType(spaceData.content_access);
			if (spaceData.photo_large) {
				if (!photoIsRemoved) {
					setProfileImageBlobPreview(spaceData.photo_large);
				}
			}
			if (spaceData.video) {
				if (!videoIsRemoved) {
					setProfileVideoBlobPreview(spaceData.video);
				}
			}
			if (spaceData.background_photo) {
				if (!photoBGIsRemoved) {
					setBackgroundImageBlobPreview(spaceData.background_photo);
				}
			}
			if (spaceData.background_video) {
				if (!videoBGIsRemoved) {
					setBackgroundVideoBlobPreview(spaceData.background_video);
				}
			}

			await manageSpaceProfileForm
				.validateFields()
				.then(() => {
					setFormHasError(false);
				})
				.catch((errors) => {
					setFormHasError(true);
				});
		},
		[photoIsRemoved, videoIsRemoved, photoBGIsRemoved, videoBGIsRemoved]
	);

	const openContentTypeModal = useCallback(
		async (value) => {
			setSelectedContentType(value);
			if (value === 'has_explicit') {
				setModalOpenExplicitContent(true);
			} else {
				// setSelectedExplicitContent([]);
			}
		},
		[selectedExplicitContent]
	);

	const openAccessTypeModal = useCallback(
		async (value) => {
			setSelectedAccessType(value);
			if (value === 'has_explicit') {
				setModalOpenExplicitAccess(true);
			} else {
				// setSelectedExplicitAccess([]);
			}
		},
		[selectedExplicitAccess]
	);

	useEffect(() => {
		if (spaceInfo) {
			dispatch(hidePageContentLoader());
			setFormSpaceData(spaceInfo).then((r) => {});
		} else {
			dispatch(displayPageContentLoader());
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, spaceInfo, profileImageError, backgroundImageError]);

	console.log(manageSpaceProfileForm.getFieldError('instagram'));

	return (
		<>
			<div className="cs-dash-card block-full card">
				<div className="cs-stack-heading">
					<div className="stack-row split">
						<h1>Space Profile</h1>
					</div>
					<p>
						Your Space Profile information is used for details in your Space and
						in different areas.{' '}
						<a>
							Learn more about your space profile settings.
						</a>
					</p>
				</div>
			</div>

			<div className="cs-dash-card block-full card">
				<div className="cs-stack-form-guided">
					<div className="stack-row-wrap split">
						<div>
							<h2>Profile Details</h2>
						</div>
						<p>Space Basics</p>
					</div>

					<div className="formrow media">
						<div className="cs-stack-form-media stack-row">
							<div className="cs-stack-form-media-item">
								<UploadDraggerV2
									onError={setProfileImageError}
									cropperAspectRatio={1 / 1}
									shape="circle"
									hideDragIcon="true"
									hideText="true"
									uploadFileTypes={IMAGE_FILETYPES.toString()}
									uploadedFilePreview={profileImageBlobPreview}
									onUploadFileChange={handleProfileImageUploadFileChange}
									minHeight={IMAGE_STANDARD_MIN_HEIGHT}
									minWidth={IMAGE_STANDARD_MIN_WIDTH}
									maxHeight={IMAGE_STANDARD_MAX_HEIGHT}
									maxWidth={IMAGE_STANDARD_MAX_WIDTH}
									uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}
									disableCropper={true}
								/>
								<p className="cs-label">Space Profile Image</p>
								<p className="cs-helper">Up to 1920px JPEG, PNG, or GIF</p>
								{profileImageError && (
									<div className="alert callout warning !w-full items-center">
										<div className="action">
											<i className="fa-solid fa-triangle-exclamation"></i>
										</div>
										<div className="content">
											<div>
												<h6>{profileImageError.message}</h6>
											</div>
										</div>
									</div>
								)}
							</div>

							<div className="cs-stack-form-media-item">
								<UploadDraggerV2
									onError={setProfileVideoError}
									shape="circle"
									hideDragIcon="true"
									hideText="true"
									uploadFileTypes={VIDEO_FILETYPES.toString()}
									uploadedFilePreview={profileVideoBlobPreview}
									onUploadFileChange={handleProfileVideoUploadFileChange}
									minHeight={VIDEO_STANDARD_MIN_HEIGHT}
									minWidth={VIDEO_STANDARD_MIN_WIDTH}
									maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
									maxWidth={VIDEO_STANDARD_MAX_WIDTH}
									uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
								/>
								<p className="cs-label">Space Profile Video</p>
								<p className="cs-helper">Optional up to 1920px/1080p MOV or MP4</p>
								{profileVideoError && (
									<div className="alert callout warning !w-full items-center">
										<div className="action">
											<i className="fa-solid fa-triangle-exclamation"></i>
										</div>
										<div className="content">
											<div>
												<h6>{profileVideoError.message}</h6>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className="cs-formrow-helper-split">
							<p>The Profile Image (up to 4MB) for your Space is displayed on the standard homepage and is used in various areas. A square format is preferred. Required.</p>
							<p>The Profile Video (up to 12MB) for your Space replaces the Profile Image in some areas on limited devices only. A square format is preferred. Optional.</p>
						</div>
					</div>
					<div className="formrow media">
						<div className="cs-stack-form-media stack-row">
							<div className="cs-stack-form-media-item">
								<UploadDraggerV2
									onError={setBackgroundImageError}
									cropperAspectRatio={16 / 9}
									hideDragIcon="true"
									hideText="true"
									shape="video"
									uploadFileTypes={IMAGE_FILETYPES.toString()}
									uploadedFilePreview={backgroundImageBlobPreview}
									onUploadFileChange={handleBackgroundImageUploadFileChange}
									minHeight={IMAGE_LARGE_MIN_HEIGHT}
									minWidth={IMAGE_LARGE_MIN_WIDTH}
									maxHeight={IMAGE_LARGE_MAX_HEIGHT}
									maxWidth={IMAGE_LARGE_MAX_WIDTH}
									uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
									disableCropper={true}
								/>
								<p className="cs-label">Space Profile Background Image</p>
								<p className="cs-helper">Up to 3840px JPEG, PNG, or GIF</p>
								{backgroundImageError && (
									<div className="alert callout warning !w-full items-center">
										<div className="action">
											<i className="fa-solid fa-triangle-exclamation"></i>
										</div>
										<div className="content">
											<div>
												<h6>{backgroundImageError.message}</h6>
											</div>
										</div>
									</div>
								)}
							</div>
							<div className="cs-stack-form-media-item">
								<UploadDraggerV2
									onError={setBackgroundVideoError}
									hideDragIcon="true"
									hideText="true"
									shape="video"
									uploadFileTypes={VIDEO_FILETYPES.toString()}
									uploadedFilePreview={backgroundVideoBlobPreview}
									onUploadFileChange={handleBackgroundVideoUploadFileChange}
									minHeight={VIDEO_STANDARD_MIN_HEIGHT}
									minWidth={VIDEO_STANDARD_MIN_WIDTH}
									maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
									maxWidth={VIDEO_STANDARD_MAX_WIDTH}
									uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
								/>
								<p className="cs-label">Space Profile Background Video</p>
								<p className="cs-helper">Optional up to 1920px/1080p MOV or MP4</p>
								{backgroundVideoError && (
									<div className="alert callout warning !w-full items-center">
										<div className="action">
											<i className="fa-solid fa-triangle-exclamation"></i>
										</div>
										<div className="content">
											<div>
												<h6>{backgroundVideoError.message}</h6>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className="cs-formrow-helper-split">
							<p>The Background Image (up to 4MB) for your Space is displayed on the standard homepage and is used in various areas. A square or wide format is preferred. Required.</p>
							<p>The Background Video (up to 12MB) for your Space replaces the Profile Image in some areas on limited devices only. A square or wide format is preferred. Optional.</p>
						</div>
					</div>

					<Form
						form={manageSpaceProfileForm}
						name="manageSpaceProfileForm"
						id="manageSpaceProfileForm"
						validateMessages={VALIDATE_MESSAGES}
						onFocus={() => {
							setFormIsOnFocus(true);
						}}
						onBlur={() => {
							// setTimeout(() => {
							setFormIsOnFocus(false);
							handleFormOnBlur().then(r => {});
							// }, 200);
						}}
						onChange={handleFormChanges}
						onFinish={handleSubmit}
						className='w-full stack-form-col'
					>
						<Form.Item
							name="display_name"
							label="Display Name"
							validateTrigger="onBlur"
							rules={[
								{ required: true },
								{
									min: 2,
									message: 'Must be minimum 2 characters.'
								},
								{
									max: 32,
									message: 'Must not more than 32 characters.'
								}
							]}
						>
							<div className='formrow'>
								<div className="form-input air">
									<label htmlFor="text">Display Name</label>
									<input id="text" value={displayName} name="text" type="text" required placeholder="2 Up to 32 characters, including spaces."/>
									{manageSpaceProfileForm.getFieldsError()[0]?.errors.length > 0 && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content content-center">
												<div>
													<h6>{manageSpaceProfileForm.getFieldsError()[0]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="cs-formrow-helper-end">
									<p>This is the displayed name of your Space. Usually it’s the name of your business, group, or community.</p>
								</div>
							</div>
						</Form.Item>

						<Form.Item
							name="username"
							label="Username"
							validateTrigger="onBlur"
							rules={[
								{ required: true },
								{
									min: 2,
									message: 'Must be minimum 2 of characters.'
								},
								{
									max: 32,
									message: 'Must not exceed 32 characters.'
								},
								() => ({
									validator: async (_, value) => {

										if (value) {
											const data = {
												shortcode: value
											};
											if (spaceInfo) {
												data['space_id'] = spaceInfo.id;
											}
											let available = false;
											const response =
												await checkUsernameAvailability(data);
											if (response && response.result) {
												available = response.availability;
											}
											if (!available) {
												return Promise.reject(
													new Error(
														'Username not available.'
													)
												);
											}
										}
										if (
											(value && value.match(/\ /g)) ||
											/[`!@#$%^&*()+=\[\]{};':"\\|,<>\/?~]/.test(
												value
											)
										) {
											return Promise.reject(
												new Error('Invalid Input')
											);
										}
										return Promise.resolve();
									}
								})
							]}
						>
							<div className='formrow'>
								<div className="form-input air">
									<label htmlFor="text">Username</label>
									<input id="text" name="text" value={username} type="text" required placeholder="2 to 32 characters. Letters, numbers, dashes, periods, and underscores only."></input>
									{manageSpaceProfileForm.getFieldsError()[1]?.errors.length > 0 && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content content-center">
												<div>
													<h6>{manageSpaceProfileForm.getFieldsError()[1]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="cs-formrow-helper-end">
									<p>Your Space has a unique @username for tagging and mentions. This is the same type as Members’ usernames and cannot be the same as another Space or Member.</p>
								</div>
							</div>
						</Form.Item>

						<Form.Item
							name="shortcode"
							label="Short Code"
							validateTrigger="onBlur"
							rules={[
								{ required: true },
								{
									min: 2,
									message: 'Must be minimum 2 characters.'
								},
								{
									max: 8,
									message: 'Must not exceed 8 characters.'
								},
								() => ({
									async validator(_, value) {
										if (value) {
											const data = {
												shortcode: value
											};

											if (spaceInfo) {
												data['space_id'] = spaceInfo.id;
											}

											let available = false;
											const response =
												await checkShortCodeAvailability(data);
											if (response && response.result) {
												available = response.availability;
											}

											if (!available && value.length <= 8) {
												return Promise.reject(
													new Error(
														'Shortcode not available.'
													)
												);
											}
										}

										if (
											(value && value.match(/\ /g)) ||
											/[`!@#$%^&*()+=\[\]{};':"\\|,<>\/?~]/.test(
												value
											)
										) {
											return Promise.reject(
												new Error('Invalid Input')
											);
										}
										return Promise.resolve();
									}
								})
							]}
						>
							<div className='formrow'>
								<div className="form-input air">
									<label htmlFor="text">Shortcode</label>
									<input id="text" name="text" value={spaceShortCode} type="text" required placeholder="2 to 8 characters. Letters and numbers only, no spaces."/>
									{manageSpaceProfileForm.getFieldsError()[2]?.errors.length > 0 && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content content-center">
												<div>
													<h6>{manageSpaceProfileForm.getFieldsError()[2]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className='cs-formrow-helper-end'>
									<p>Choose a unique Space Shortcode based on your Space’s name. This is used in transactions, support, and other areas. <a href="">Suggest an available Space Shortcode.</a></p>
								</div>
							</div>
						</Form.Item>

						<Form.Item
							name="location"
							label="Location"
							validateTrigger="onBlur"
							rules={[
								{
									max: 32,
									message: 'Must not exceed 32 characters.'
								},
							]}
						>
							<div className='formrow'>
								<div className="form-input air">
									<label htmlFor="text">Location</label>
									<input id="location" value={location} name="location" type="text" placeholder="Up to 32 characters, including spaces."/>
									{manageSpaceProfileForm.getFieldsError()[3]?.errors.length > 0 && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content content-center">
												<div>
													<h6>{manageSpaceProfileForm.getFieldsError()[3]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className='cs-formrow-helper-end'>
									<p>This is the displayed location of your community, such as a city, state, region, country, place, or coverage area. Optional. <a href="#generate">Suggest an available Space Shortcode.</a></p>
								</div>
							</div>
						</Form.Item>

						<Form.Item
							label="Website"
							name="website"
							validateTrigger="onBlur"
							rules={[
								{
									pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
									message: 'Please Enter a valid URL'
								}
							]}
						>
							<div className='formrow'>
								<div className="form-input air">
									<label htmlFor="text">Website</label>
									<input id="website" value={website} name="website" type="text" placeholder="www.domain.com"/>
									{manageSpaceProfileForm.getFieldsError()[4]?.errors.length > 0 && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content content-center">
												<div>
													<h6>{manageSpaceProfileForm.getFieldsError()[4]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className='cs-formrow-helper-end'>
									<p>Display your primary domain name for your business, group, or community by adding it here. Optional.</p>
								</div>
							</div>
						</Form.Item>

						<Form.Item
							name="about"
							label="About"
							rules={[
								{
									max: 255,
									message: 'Must not exceed 255 characters.'
								}
							]}
						>
							<div className='formrow'>
								<div className="form-textarea air">
									<label htmlFor="shortdesc">Short Description</label>
									<textarea id="about" name="about" value={about} placeholder="Up to 255 characters, including spaces." rows="3" required></textarea>
									{manageSpaceProfileForm.getFieldsError()[5]?.errors.length > 0 && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content content-center">
												<div>
													<h6>{manageSpaceProfileForm.getFieldsError()[5]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div>
									<p>Share some details about your business, group, or community. Recommended.</p>
								</div>
							</div>
						</Form.Item>

						<Form.Item
							label="Instagram"
							name="instagram"
							validateTrigger="onBlur"
							rules={[
								{
									pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
									message: 'Please Enter a valid URL'
								}
							]}
						>
							<div className='formrow'>
								<div className="form-input air">
									<label htmlFor="text">Instagram</label>
									<input id="instagram" value={instagram} name="instagram" type="text" placeholder="instagram.com/username"/>
									{manageSpaceProfileForm.getFieldsError()[6]?.errors.length > 0 && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content content-center">
												<div>
													<h6>{manageSpaceProfileForm.getFieldsError()[6]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className='cs-formrow-helper-end'>
									{/*<p>Display your primary domain name for your business, group, or community by adding it here. Optional.</p>*/}
								</div>
							</div>
						</Form.Item>

						<Form.Item
							label="Youtube"
							name="youtube"
							validateTrigger="onBlur"
							rules={[
								{
									pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
									message: 'Please Enter a valid URL'
								}
							]}
						>
							<div className='formrow'>
								<div className="form-input air">
									<label htmlFor="text">Youtube</label>
									<input id="youtube" value={youtube} name="youtube" type="text" placeholder="youtube.com/@handle"/>
									{manageSpaceProfileForm.getFieldsError()[7]?.errors.length > 0 && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content content-center">
												<div>
													<h6>{manageSpaceProfileForm.getFieldsError()[7]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className='cs-formrow-helper-end'>
									{/*<p>Display your primary domain name for your business, group, or community by adding it here. Optional.</p>*/}
								</div>
							</div>
						</Form.Item>

						<Form.Item
							label="TikTok"
							name="tiktok"
							validateTrigger="onBlur"
							rules={[
								{
									pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
									message: 'Please Enter a valid URL'
								}
							]}
						>
							<div className='formrow'>
								<div className="form-input air">
									<label htmlFor="text">TikTok</label>
									<input id="tiktok" value={tiktok} name="tiktok" type="text" placeholder="tiktok.com/@username"/>
									{manageSpaceProfileForm.getFieldsError()[8]?.errors.length > 0 && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content content-center">
												<div>
													<h6>{manageSpaceProfileForm.getFieldsError()[8]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className='cs-formrow-helper-end'>
									{/*<p>Display your primary domain name for your business, group, or community by adding it here. Optional.</p>*/}
								</div>
							</div>
						</Form.Item>
						<Form.Item
							label="Twitch"
							name="twitch"
							validateTrigger="onBlur"
							rules={[
								{
									pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
									message: 'Please Enter a valid URL'
								}
							]}
						>
							<div className='formrow'>
								<div className="form-input air">
									<label htmlFor="text">Twitch</label>
									<input id="twitch" value={twitch} name="twitch" type="text" placeholder="twitch.tv/username"/>
									{manageSpaceProfileForm.getFieldsError()[9]?.errors.length > 0 && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content content-center">
												<div>
													<h6>{manageSpaceProfileForm.getFieldsError()[9]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className='cs-formrow-helper-end'>
									{/*<p>Display your primary domain name for your business, group, or community by adding it here. Optional.</p>*/}
								</div>
							</div>
						</Form.Item>
						<Form.Item
							label="Facebook"
							name="facebook"
							validateTrigger="onBlur"
							rules={[
								{
									pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
									message: 'Please Enter a valid URL'
								}
							]}
						>
							<div className='formrow'>
								<div className="form-input air">
									<label htmlFor="text">Facebook</label>
									<input id="facbook" value={facebook} name="facebook" type="text" placeholder="fb.com/username"/>
									{manageSpaceProfileForm.getFieldsError()[10]?.errors.length > 0 && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content content-center">
												<div>
													<h6>{manageSpaceProfileForm.getFieldsError()[10]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className='cs-formrow-helper-end'>
									{/*<p>Display your primary domain name for your business, group, or community by adding it here. Optional.</p>*/}
								</div>
							</div>
						</Form.Item>
						<Form.Item
							label="LinkedIn"
							name="linkedin"
							validateTrigger="onBlur"
							rules={[
								{
									pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
									message: 'Please Enter a valid URL'
								}
							]}
						>
							<div className='formrow'>
								<div className="form-input air">
									<label htmlFor="text">LinkedIn</label>
									<input id="linkedin" value={linkedin} name="linkedin" type="text" placeholder="linkedin.com/in/username"/>
									{manageSpaceProfileForm.getFieldsError()[11]?.errors.length > 0 && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content content-center">
												<div>
													<h6>{manageSpaceProfileForm.getFieldsError()[11]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className='cs-formrow-helper-end'>
									{/*<p>Display your primary domain name for your business, group, or community by adding it here. Optional.</p>*/}
								</div>
							</div>
						</Form.Item>
						<Form.Item
							label="X"
							name="x"
							validateTrigger="onBlur"
							rules={[
								{
									pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
									message: 'Please Enter a valid URL'
								}
							]}
						>
							<div className='formrow'>
								<div className="form-input air">
									<label htmlFor="text">X</label>
									<input id="x" value={x} name="x" type="text" placeholder="x.com/username"/>
									{manageSpaceProfileForm.getFieldsError()[12]?.errors.length > 0 && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content content-center">
												<div>
													<h6>{manageSpaceProfileForm.getFieldsError()[12]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className='cs-formrow-helper-end'>
									{/*<p>Display your primary domain name for your business, group, or community by adding it here. Optional.</p>*/}
								</div>
							</div>
						</Form.Item>

						{/*<div className="formrow ">*/}
						{/*	<div className="cs-stack-form-link-icons">*/}
						{/*		<p>Links</p>*/}
						{/*		<div className="cs-stack-link-icons stack-row-wrap">*/}
						{/*			<a href="https://x.com/#" target="_blank" className="button-icon-round-gradient-alt active"><span><p><i className="fa-brands fa-x-twitter"></i></p></span></a>*/}
						{/*			<a href="https://instagram.com/#" target="_blank" className="button-icon-round-gradient-alt"><span><p><i className="fa-brands fa-instagram"></i></p></span></a>*/}
						{/*			<a href="https://facebook.com/#" target="_blank" className="button-icon-round-gradient-alt"><span><p><i className="fa-brands fa-facebook-f"></i></p></span></a>*/}
						{/*			<a href="https://tiktok.com/#" target="_blank" className="button-icon-round-gradient-alt"><span><p><i className="fa-brands fa-tiktok"></i></p></span></a>*/}
						{/*			<a href="https://linkedin.com/#" target="_blank" className="button-icon-round-gradient-alt"><span><p><i className="fa-brands fa-linkedin-in"></i></p></span></a>*/}
						{/*			<a href="https://twitch.tv/#" target="_blank" className="button-icon-round-gradient-alt"><span><p><i className="fa-brands fa-twitch"></i></p></span></a>*/}
						{/*			<a href="#" target="_blank" className="button-icon-round-gradient-alt"><span><p><i className="fa-regular fa-globe"></i></p></span></a>*/}
						{/*		</div>*/}
						{/*	</div>*/}
						{/*	<div className="cs-formrow-helper-end">*/}
						{/*		<p>Link your social media and related links here. Recommended.</p>*/}
						{/*	</div>*/}
						{/*</div>*/}
						<div className="formrow ">
							<div className="cs-stack-form-buttons">
								<DropdownWithItems
									title="Select Content Type"
									customButtonClass="button-dropdown-gradient-alt secondary "
									displayError={
										displayDropDownError && selectedContentType === 'default'
									}
									selectedValue={selectedContentType}
									listItems={CONTENT_TYPES_LIST_WITH_DEFAULT}
									onDropdownChange={(value) => openContentTypeModal(value)}
								/>
								<ExplicitContent
									modalOpen={modalOpenExplicitContent}
									setModalOpen={setModalOpenExplicitContent}
									selectedItems={selectedExplicitContent}
									isExplicitContent={true}
									onSelectedItems={(value) => setSelectedExplicitContent(value)}
								/>
							</div>
							<div>
								<p>Specify what type of Content your Space will contain. <a href="link-via-admin" target="_blank">View our Hosted Content Guidelines.</a></p>
							</div>
						</div>
						<div className="formrow ">
							<DropdownWithItems
								title="Select Access Type"
								customButtonClass="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
								displayError={
									displayDropDownError && selectedAccessType === 'default'
								}
								selectedValue={selectedAccessType}
								listItems={ACCESS_TYPES_LIST_WITH_DEFAULT}
								onDropdownChange={(value) => openAccessTypeModal(value)}
							/>
							<ExplicitContent
								modalOpen={modalOpenExplicitAccess}
								setModalOpen={setModalOpenExplicitAccess}
								selectedItems={selectedExplicitAccess}
								isExplicitContent={false}
								onSelectedItems={(value) => setSelectedExplicitAccess(value)}
							/>
							<div>
								<p>Specify what type of Content your Space will provide access to. <a href="link-via-admin" target="_blank">View our Linked Content Guidelines.</a></p>
							</div>
						</div>
						{alert && (
							<div className="formrow">
								<div className={"alert banner px-section-safeview" + (alert.variant === 'danger' ? " error" : " success")}>
									<div className="action">
										<i className={"fa-solid" + (alert.variant === 'danger' ? " fa-triangle-exclamation" : " fa-circle-check")}/>
									</div>
									<div className="content">
										<div>
											<h6>{alert.message}</h6>
											<p></p>
										</div>
									</div>
									<div className="action"
										onClick={()=>{
											setAlert(null)
										}}
									>
										<a>
											<i className="fa-solid fa-xmark"/>
										</a>
									</div>
								</div>
								<div></div>
							</div>
						)}
						<div className="formrow">
							<button
								type="submit"
								className="primary-gradient"
								disabled={submitted || formHasError}
							>
								<span>
									<p>
										{submitted && (
											<i className="fa-solid fa-spinner fa-spin"></i>
										)}
										Save
									</p>
								</span>
							</button>
							<div className="cs-formrow-helper-none">
								<p></p>
							</div>
						</div>
					</Form>
				</div>
			</div>

			{/*<div className="block-full card">*/}
			{/*	<div className="manage-space">*/}
			{/*		<div className="dashboard-wrapper">*/}
			{/*			<div className="grid grid-cols-12 md:gap-8">*/}
			{/*				<div className="col-span-12">*/}
			{/*					<h3 className="header-medium">Space Profile</h3>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="grid grid-cols-12 md:gap-8 mt-5">*/}
			{/*				<div className="col-span-12">*/}
			{/*					<p className="body-text--small">*/}
			{/*						Your Space Profile information is used for details in your Space and*/}
			{/*						in different areas.{' '}*/}
			{/*						<a className="body-txtitalic--small gradient-color-txt">*/}
			{/*							Learn more about your space profile settings.*/}
			{/*						</a>*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*		<div className="dashboard-wrapper mt-5">*/}
			{/*			<div className="grid grid-cols-12 md:gap-8">*/}
			{/*				<div className="col-span-6">*/}
			{/*					<h3 className="header-small">Profile Details</h3>*/}
			{/*				</div>*/}
			{/*				<div className="col-span-6">*/}
			{/*					<p className="body-text--small text-end">Space Basics</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<Form*/}
			{/*				form={manageSpaceProfileForm}*/}
			{/*				name="manageSpaceProfileForm"*/}
			{/*				id="manageSpaceProfileForm"*/}
			{/*				validateMessages={VALIDATE_MESSAGES}*/}
			{/*				onFocus={() => {*/}
			{/*					setFormIsOnFocus(true);*/}
			{/*				}}*/}
			{/*				onBlur={() => {*/}
			{/*					setTimeout(() => {*/}
			{/*						setFormIsOnFocus(false);*/}
			{/*						handleFormOnBlur();*/}
			{/*					}, 200);*/}
			{/*				}}*/}
			{/*				onChange={handleFormChanges}*/}
			{/*				onFinish={handleSubmit}*/}
			{/*				className={formIsOnFocus ? 'hide-antd-error-messages' : ''}*/}
			{/*			>*/}
			{/*				<div className="grid grid-cols-1 md:grid-cols-3 md:gap-8 mt-5">*/}
			{/*					<div className='max-[768px]:mt-4'>*/}
			{/*						<div className="upload-circ no-bg">*/}
			{/*							<UploadDragger*/}
			{/*								passedError={profileImageError}*/}
			{/*								cropperAspectRatio={1 / 1}*/}
			{/*								circular="true"*/}
			{/*								hideDragIcon="true"*/}
			{/*								hideText="true"*/}
			{/*								uploadFileTypes={IMAGE_FILETYPES.toString()}*/}
			{/*								uploadedFilePreview={profileImageBlobPreview}*/}
			{/*								onUploadFileChange={handleProfileImageUploadFileChange}*/}
			{/*								minHeight={IMAGE_STANDARD_MIN_HEIGHT}*/}
			{/*								minWidth={IMAGE_STANDARD_MIN_WIDTH}*/}
			{/*								maxHeight={IMAGE_STANDARD_MAX_HEIGHT}*/}
			{/*								maxWidth={IMAGE_STANDARD_MAX_WIDTH}*/}
			{/*								uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}*/}
			{/*								disableCropper={true}*/}
			{/*							/>*/}
			{/*							<i className="fa-regular fa-plus"></i>*/}
			{/*						</div>*/}
			{/*						<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">*/}
			{/*							Space Profile Image**/}
			{/*						</p>*/}
			{/*						<p className="text-center body-txtitalic--smallest color-light">*/}
			{/*							1920px square preferred. JPEG, PNG, or GIF*/}
			{/*						</p>*/}
			{/*						<p className="mt-2 body-txtitalic--smallest block md:hidden text-center">*/}
			{/*							The Profile Image (up to 4MB) for your Space is displayed on the*/}
			{/*							standard homepage and is used in various areas. Required.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*					<div className='max-[768px]:mt-4'>*/}
			{/*						<div className="upload-circ no-bg">*/}
			{/*							<UploadDragger*/}
			{/*								circular="true"*/}
			{/*								hideDragIcon="true"*/}
			{/*								hideText="true"*/}
			{/*								uploadFileTypes={VIDEO_FILETYPES.toString()}*/}
			{/*								uploadedFilePreview={profileVideoBlobPreview}*/}
			{/*								onUploadFileChange={handleProfileVideoUploadFileChange}*/}
			{/*								minHeight={VIDEO_STANDARD_MIN_HEIGHT}*/}
			{/*								minWidth={VIDEO_STANDARD_MIN_WIDTH}*/}
			{/*								maxHeight={VIDEO_STANDARD_MAX_HEIGHT}*/}
			{/*								maxWidth={VIDEO_STANDARD_MAX_WIDTH}*/}
			{/*								uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}*/}
			{/*							/>*/}
			{/*							<i className="fa-regular fa-plus"></i>*/}
			{/*						</div>*/}
			{/*						<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">*/}
			{/*							Space Profile Video*/}
			{/*						</p>*/}
			{/*						<p className="text-center body-txtitalic--smallest color-light">*/}
			{/*							Up to 1920px/1080p, square preferred. MOV or MP4*/}
			{/*						</p>*/}
			{/*						<p className="mt-2 body-txtitalic--smallest block md:hidden text-center">*/}
			{/*							The Profile Video (up to 12MB) for your Space replaces the*/}
			{/*							Profile Image in some areas on limited devices only. Optional.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*					<div className='hidden md:block'>*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							The Profile Image (up to 4MB) for your Space is displayed on the*/}
			{/*							standard homepage and is used in various areas. Required.*/}
			{/*						</p>*/}
			{/*						<p className="mt-5 body-txtitalic--smallest">*/}
			{/*							TThe Profile Video (up to 12MB) for your Space replaces the*/}
			{/*							Profile Image in some areas on limited devices only. Optional.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="grid grid-cols-1 md:grid-cols-3  md:gap-8 mt-5">*/}
			{/*					<div className='max-[768px]:mt-4'>*/}
			{/*						<div className="upload-rec">*/}
			{/*							<UploadDragger*/}
			{/*								passedError={backgroundImageError}*/}
			{/*								cropperAspectRatio={16 / 9}*/}
			{/*								hideDragIcon="true"*/}
			{/*								hideText="true"*/}
			{/*								uploadFileTypes={IMAGE_FILETYPES.toString()}*/}
			{/*								uploadedFilePreview={backgroundImageBlobPreview}*/}
			{/*								onUploadFileChange={handleBackgroundImageUploadFileChange}*/}
			{/*								minHeight={IMAGE_STANDARD_MIN_HEIGHT}*/}
			{/*								minWidth={IMAGE_STANDARD_MIN_WIDTH}*/}
			{/*								maxHeight={IMAGE_STANDARD_MAX_HEIGHT}*/}
			{/*								maxWidth={IMAGE_STANDARD_MAX_WIDTH}*/}
			{/*								uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}*/}
			{/*								disableCropper={true}*/}
			{/*							/>*/}
			{/*							<i className="fa-regular fa-plus"></i>*/}
			{/*						</div>*/}
			{/*						<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">*/}
			{/*							Space Profile Background Image**/}
			{/*						</p>*/}
			{/*						<p className="text-center body-txtitalic--smallest">*/}
			{/*							Up to 3840px square or wide. <br />*/}
			{/*							JPEG, PNG, or GIF*/}
			{/*						</p>*/}
			{/*						<p className="mt-2 body-txtitalic--smallest block md:hidden text-center">*/}
			{/*							The Background Image (up to 8MB) for your Space is displayed on*/}
			{/*							the standard homepage and is used in various areas. Required.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*					<div className='max-[768px]:mt-4'>*/}
			{/*						<div className="upload-rec">*/}
			{/*							<UploadDragger*/}
			{/*								hideDragIcon="true"*/}
			{/*								hideText="true"*/}
			{/*								uploadFileTypes={VIDEO_FILETYPES.toString()}*/}
			{/*								uploadedFilePreview={backgroundVideoBlobPreview}*/}
			{/*								onUploadFileChange={handleBackgroundVideoUploadFileChange}*/}
			{/*								minHeight={VIDEO_STANDARD_MIN_HEIGHT}*/}
			{/*								minWidth={VIDEO_STANDARD_MIN_WIDTH}*/}
			{/*								maxHeight={VIDEO_STANDARD_MAX_HEIGHT}*/}
			{/*								maxWidth={VIDEO_STANDARD_MAX_WIDTH}*/}
			{/*								uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}*/}
			{/*							/>*/}
			{/*							<i className="fa-regular fa-plus"></i>*/}
			{/*						</div>*/}
			{/*						<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">*/}
			{/*							Space Profile Background Video*/}
			{/*						</p>*/}
			{/*						<p className="text-center body-txtitalic--smallest">*/}
			{/*							Up to 1920px/1080px, square or wide. <br />*/}
			{/*							MOV or MP4*/}
			{/*						</p>*/}
			{/*						<p className="body-txtitalic--smallest mt-2 block md:hidden text-center">*/}
			{/*							The Background Video (up to 16MB) for your Space is displayed on*/}
			{/*							the standard homepage and some areas on limited devices.*/}
			{/*							Optional.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*					<div className='hidden md:block'>*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							The Background Image (up to 8MB) for your Space is displayed on*/}
			{/*							the standard homepage and is used in various areas. Required.*/}
			{/*						</p>*/}
			{/*						<p className="body-txtitalic--smallest mt-5">*/}
			{/*							The Background Video (up to 16MB) for your Space is displayed on*/}
			{/*							the standard homepage and some areas on limited devices.*/}
			{/*							Optional.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 mt-4 flex  md:gap-8 items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<div className="input-group-com w-full mt-2">*/}
			{/*							<label className="body-text--small gradient-color-txt w-1/4">*/}
			{/*								Name*/}
			{/*							</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Name"*/}
			{/*								name="display_name"*/}
			{/*								className="mb-0 hidden-label z-0"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									{*/}
			{/*										min: 2,*/}
			{/*										message: 'Must be minimum 2 characters.'*/}
			{/*									},*/}
			{/*									{*/}
			{/*										max: 32,*/}
			{/*										message: 'Must not more than 32 characters.'*/}
			{/*									}*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<Input*/}
			{/*									placeholder="2 Up to 32 characters, including spaces."*/}
			{/*									className="input-md body-text--small color-reg"*/}
			{/*									maxLength={32}*/}
			{/*									disabled={submitted}*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							This is the displayed name of your Space. Usually it’s the name*/}
			{/*							of your business, group, or community.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="mt-4 grid grid-cols-12 mt-4  md:gap-8 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<div className="input-group-com w-full mt-2">*/}
			{/*							<label className="body-text--small gradient-color-txt w-[100px] md:w-1/4">*/}
			{/*								Username*/}
			{/*							</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Username"*/}
			{/*								name="username"*/}
			{/*								className="mb-0 hidden-label  z-0"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									{*/}
			{/*										min: 2,*/}
			{/*										message: 'Must be minimum 2 of characters.'*/}
			{/*									},*/}
			{/*									{*/}
			{/*										max: 32,*/}
			{/*										message: 'Must not exceed 32 characters.'*/}
			{/*									},*/}
			{/*									({ getFieldValue }) => ({*/}
			{/*										async validator(_, value) {*/}
			{/*											// TODO: find way to trigger username API check on form submit to avoid flood requests*/}
			{/*											// if (!formIsOnFocus && value) {*/}
			{/*											if (value) {*/}
			{/*												const data = {*/}
			{/*													username: username*/}
			{/*												};*/}

			{/*												if (spaceInfo) {*/}
			{/*													data['current_space_id'] = spaceInfo.id;*/}
			{/*												}*/}

			{/*												let available = false;*/}
			{/*												const response =*/}
			{/*													await checkUsernameAvailability(data);*/}
			{/*												if (response && response.result) {*/}
			{/*													available = response.availability;*/}
			{/*												}*/}

			{/*												if (!available) {*/}
			{/*													return Promise.reject(*/}
			{/*														new Error('Username not available.')*/}
			{/*													);*/}
			{/*												}*/}
			{/*											}*/}

			{/*											if (*/}
			{/*												(value && value.match(/\ /g)) ||*/}
			{/*												/[`!@#$%^&*()+=\[\]{};':"\\|,<>\/?~]/.test(*/}
			{/*													value*/}
			{/*												)*/}
			{/*											) {*/}
			{/*												return Promise.reject(*/}
			{/*													new Error('Invalid Input')*/}
			{/*												);*/}
			{/*											}*/}

			{/*											return Promise.resolve();*/}
			{/*										}*/}
			{/*									})*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<Input*/}
			{/*									placeholder="2 to 32 characters. Letters, numbers, dashes, periods, and underscores only."*/}
			{/*									className="input-md body-text--small color-reg"*/}
			{/*									maxLength={32}*/}
			{/*									disabled={submitted}*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Your Space has a unique @username for tagging and mentions. This*/}
			{/*							is the same type as Members’ usernames and cannot be the same as*/}
			{/*							another Space or Member.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="mt-4 grid grid-cols-12 mt-4  md:gap-8 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<div className="input-group-com w-full mt-2">*/}
			{/*							<label className="body-text--small gradient-color-txt w-[100px] md:w-1/4">*/}
			{/*								Shortcode*/}
			{/*							</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Shortcode"*/}
			{/*								name="shortcode"*/}
			{/*								className="mb-0 hidden-label  z-0"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									{*/}
			{/*										min: 2,*/}
			{/*										message: 'Must be minimum 2 characters.'*/}
			{/*									},*/}
			{/*									{*/}
			{/*										max: 8,*/}
			{/*										message: 'Must not exceed 8 characters.'*/}
			{/*									},*/}
			{/*									({ getFieldValue }) => ({*/}
			{/*										async validator(_, value) {*/}
			{/*											if (value) {*/}
			{/*												const data = {*/}
			{/*													space_id: spaceInfo.id,*/}
			{/*													shortcode: value*/}
			{/*												};*/}

			{/*												let available = false;*/}
			{/*												const response =*/}
			{/*													await checkShortCodeAvailability(data);*/}
			{/*												if (response && response.result) {*/}
			{/*													available = response.availability;*/}
			{/*												}*/}

			{/*												if (!available) {*/}
			{/*													return Promise.reject(*/}
			{/*														new Error(*/}
			{/*															'Shortcode not available.'*/}
			{/*														)*/}
			{/*													);*/}
			{/*												}*/}
			{/*											}*/}
			{/*											if (*/}
			{/*												(value && value.match(/\ /g)) ||*/}
			{/*												/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(*/}
			{/*													value*/}
			{/*												)*/}
			{/*											) {*/}
			{/*												return Promise.reject(*/}
			{/*													new Error('Invalid Input')*/}
			{/*												);*/}
			{/*											}*/}

			{/*											return Promise.resolve();*/}
			{/*										}*/}
			{/*									})*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<Input*/}
			{/*									placeholder="2 to 8 characters. Letters and numbers only, no spaces."*/}
			{/*									className="input-md body-text--small color-reg"*/}
			{/*									maxLength={8}*/}
			{/*									disabled={submitted}*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Choose a unique short Space Shortcode based on your Space’s*/}
			{/*							name. This is used in transactions, support, and other areas.*/}
			{/*							Suggest an available Space Shortcode.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="mt-4 grid grid-cols-12 mt-4  md:gap-8 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<div className="input-group-com w-full mt-2">*/}
			{/*							<label className="body-text--small gradient-color-txt w-[100px] md:w-50">*/}
			{/*								Location*/}
			{/*							</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Location"*/}
			{/*								name="location"*/}
			{/*								className="mb-0 hidden-label  z-0"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									{*/}
			{/*										max: 32,*/}
			{/*										message: 'Must not exceed 32 characters.'*/}
			{/*									}*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<Input*/}
			{/*									placeholder="Up to 32 characters, including spaces."*/}
			{/*									className="input-md body-text--small color-reg"*/}
			{/*									maxLength={32}*/}
			{/*									disabled={submitted}*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							This is the displayed location of your community, such as a*/}
			{/*							city, state, region, country, place, or coverage area. Optional.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="mt-4 grid grid-cols-12 mt-4  md:gap-8 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<div className="input-group-com w-full mt-2">*/}
			{/*							<label className="body-text--small gradient-color-txt w-[100px] md:w-1/4">*/}
			{/*								Website*/}
			{/*							</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Website"*/}
			{/*								name="website"*/}
			{/*								className="mb-0 hidden-label  z-0"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{*/}
			{/*										// type: 'url',*/}
			{/*										pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,*/}
			{/*										message: 'Please Enter a valid URL'*/}
			{/*									}*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<Input*/}
			{/*									placeholder="www.domain.com"*/}
			{/*									className="input-md body-text--small color-reg"*/}
			{/*									disabled={submitted}*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Display your primary domain name for your business, group, or*/}
			{/*							community by adding it here. Optional.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="mt-4 grid grid-cols-12 mt-4  md:gap-8 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<div className="input-group-com w-full mt-2">*/}
			{/*							<label className="body-text--small gradient-color-txt w-[100px] md:w-1/4">*/}
			{/*								About*/}
			{/*							</label>*/}
			{/*							<Form.Item*/}
			{/*								label="About"*/}
			{/*								name="about"*/}
			{/*								className="mb-0 hidden-label z-0"*/}
			{/*								rules={[*/}
			{/*									{*/}
			{/*										max: 255,*/}
			{/*										message: 'Must not exceed 255 characters.'*/}
			{/*									}*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<TextareaAutosize*/}
			{/*									minRows={3}*/}
			{/*									placeholder="Up to 255 characters, including spaces."*/}
			{/*									className="input-md body-text--small color-reg"*/}
			{/*									maxLength={255}*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Share some details about your business, group, or community.*/}
			{/*							Recommended.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="mt-4 grid grid-cols-12 mt-4  md:gap-8 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-2">*/}
			{/*						<p className="body-text--small gradient-color-txt m-0">*/}
			{/*							Social Links*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-6">*/}
			{/*						<ul className="list-socials">*/}
			{/*							<li>*/}
			{/*								/!*<a href="">*!/*/}
			{/*								<i className="fa-brands fa-twitter color-light"></i>*/}
			{/*								/!*</a>*!/*/}
			{/*							</li>*/}
			{/*							<li>*/}
			{/*								/!*<a href="">*!/*/}
			{/*								<i className="fa-brands fa-instagram color-light"></i>*/}
			{/*								/!*</a>*!/*/}
			{/*							</li>*/}
			{/*							<li>*/}
			{/*								/!*<a href="">*!/*/}
			{/*								<i className="fa-brands fa-facebook color-light"></i>*/}
			{/*								/!*</a>*!/*/}
			{/*							</li>*/}
			{/*							<li>*/}
			{/*								/!*<a href="">*!/*/}
			{/*								<i className="fa-brands fa-tiktok color-light"></i>*/}
			{/*								/!*</a>*!/*/}
			{/*							</li>*/}
			{/*							<li>*/}
			{/*								/!*<a href="">*!/*/}
			{/*								<i className="fa-brands fa-youtube color-light"></i>*/}
			{/*								/!*</a>*!/*/}
			{/*							</li>*/}
			{/*							<li>*/}
			{/*								/!*<a href="">*!/*/}
			{/*								<i className="fa-brands fa-linkedin color-light"></i>*/}
			{/*								/!*</a>*!/*/}
			{/*							</li>*/}
			{/*							<li>*/}
			{/*								/!*<a href="">*!/*/}
			{/*								<i className="fa-brands fa-discord color-light"></i>*/}
			{/*								/!*</a>*!/*/}
			{/*							</li>*/}
			{/*							<li>*/}
			{/*								/!*<a href="">*!/*/}
			{/*								<i className="fa-brands fa-twitch color-light"></i>*/}
			{/*								/!*</a>*!/*/}
			{/*							</li>*/}
			{/*						</ul>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Recommended. Link your social media accounts here so Members can*/}
			{/*							find them.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="mt-4 grid grid-cols-12 mt-4  md:gap-8 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<DropdownWithItems*/}
			{/*							title="Select Content Type"*/}
			{/*							dropDownClass="dropdown-bg"*/}
			{/*							customButtonClass="btn-md btn-secondary mt-2 text-[11px] sm:text-[18px]"*/}
			{/*							displayError={*/}
			{/*								displayDropDownError && selectedContentType === 'default'*/}
			{/*							}*/}
			{/*							icon={getListIcon(*/}
			{/*								CONTENT_TYPES_LIST_WITH_DEFAULT,*/}
			{/*								selectedContentType*/}
			{/*							)}*/}
			{/*							selectedValue={selectedContentType}*/}
			{/*							listItems={CONTENT_TYPES_LIST_WITH_DEFAULT}*/}
			{/*							onDropdownChange={(value) => setSelectedContentType(value)}*/}
			{/*						/>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Change the Content setting if your Details, Image, or Video*/}
			{/*							contains explicit or sensitive content.{' '}*/}
			{/*							<a className="gradient-color-txt">View our Content</a>*/}
			{/*							Guidelines.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="mt-4 grid grid-cols-12  md:gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<DropdownWithItems*/}
			{/*							title="Select Access Type"*/}
			{/*							dropDownClass="dropdown-bg"*/}
			{/*							customButtonClass="btn-md btn-secondary mt-2 text-[11px] sm:text-[18px]"*/}
			{/*							displayError={*/}
			{/*								displayDropDownError && selectedAccessType === 'default'*/}
			{/*							}*/}
			{/*							icon={getListIcon(*/}
			{/*								ACCESS_TYPES_LIST_WITH_DEFAULT,*/}
			{/*								selectedAccessType*/}
			{/*							)}*/}
			{/*							selectedValue={selectedAccessType}*/}
			{/*							listItems={ACCESS_TYPES_LIST_WITH_DEFAULT}*/}
			{/*							onDropdownChange={(value) => setSelectedAccessType(value)}*/}
			{/*						/>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Change the Access setting if your Space will provide access to*/}
			{/*							explicit or sensitive content.{' '}*/}
			{/*							<a className="gradient-color-txt">*/}
			{/*								View our Content Guidelines.*/}
			{/*							</a>*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="mt-4 grid grid-cols-12  md:gap-8 mt-4 flex items-center">*/}
			{/*					<>*/}
			{/*						{alert && (*/}
			{/*							<div className="col-span-12 mb-3">*/}
			{/*								<Alert*/}
			{/*									key={alert.variant}*/}
			{/*									variant={alert.variant}*/}
			{/*									className={*/}
			{/*										'custom-alert ' +*/}
			{/*										(alert.variant === 'danger'*/}
			{/*											? 'custom-alert-danger'*/}
			{/*											: 'custom-alert-success')*/}
			{/*									}*/}
			{/*								>*/}
			{/*									{alert.message}*/}
			{/*								</Alert>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</>*/}
			{/*					<div className="col-span-12">*/}
			{/*						<Form.Item className="mb-0">*/}
			{/*							<Button*/}
			{/*								className="btn btn-primary btn-md"*/}
			{/*								htmlType="submit"*/}
			{/*								disabled={submitted || formHasError}*/}
			{/*							>*/}
			{/*								{submitted ? (*/}
			{/*									<>*/}
			{/*										<i className="fa-solid fa-spinner fa-spin me-2"></i>*/}
			{/*										Saving*/}
			{/*									</>*/}
			{/*								) : (*/}
			{/*									<>Save</>*/}
			{/*								)}*/}
			{/*							</Button>*/}
			{/*						</Form.Item>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</Form>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</div>*/}
		</>
	);
};

export default memo(ManageProfile);
