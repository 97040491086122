import React, { memo, useEffect, useCallback, useState } from 'react';
import { Button } from 'antd';
import {useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import routes from '../../../routes';
import {getMembershipDetails, getSpaceItemData} from '../../../services/space';
import '../../SpaceBuyMembership/SpaceBuyMembership.scss'
import {MembershipItemUtils} from "../../../utils/MembershipItemUtils";
import {checkMembershipItemStatus, updateMembershipItemStatus} from "../../../services/payment_method";
import {getSitewideLink} from "../../../utils/sitewideLinks";
import {membershipItemImage} from "../../../components/commons/helpers/MembershipItemHelpers";
import SocialLinks from "../../../components/commons/SocialLinks";

const ItemActivateWalletConnect = (props) => {
    const { verifyEmailSpaceUsername, verifyEmailSpaceItemId } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { authUserInfo, loading } = useSelector((state) => state.general);
    const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
    const [initialLoad, setInitialLoad] = useState(true);
    const [item, setItem] = useState(null);
    const [isCheckStatusLoaded, setCheckStatusLoaded] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);
    const [collectionAssetType, setCollectionAssetType] = useState('image');
    const [membershipDetails, setMembershipDetails] = useState(null);

    const skipActivation = useCallback(async () => {
        const url = routes.viewUserSpace() + verifyEmailSpaceUsername;
        const fin_url = url.replace(':space_username', '@' + verifyEmailSpaceUsername) + '?id=' + verifyEmailSpaceItemId;
        navigate(fin_url);
    }, [verifyEmailSpaceUsername, verifyEmailSpaceItemId]);

    const getItem = useCallback(async (space_id, item_id) => {
        const data = {
            space_id: space_id,
            item_id: item_id
        }
        if (authUserInfo) {
            data['viewing_user_id'] = authUserInfo.id;
        }
        const response = await getSpaceItemData(data);
        if (response && response.result) {
            const itemData = response.item;
            setItem(response.item);
            if (itemData.photo || itemData.video) {
                if (itemData.photo) {
                    setCollectionAssetType('image');
                }
                if (itemData.video) {
                    setCollectionAssetType('video');
                }
            } else {
                setCollectionAssetType('image');
            }
        }
    }, []);

    // Get the Membership Details of the user
    const getMembershipDetailsQuery = useCallback(async () => {
        try {
            if (!spaceInfo) {
                return;
            }
            if (!authUserInfo) {
                return;
            }
            const params = {};
            params.space_id = spaceInfo.id;
            params.user_id = authUserInfo.id;
            params.is_get_current_membership = true;
            const response = await getMembershipDetails(params);
            if (response && response.result) {
                setMembershipDetails(response.membership_details);
            }
        } catch (error) {
            console.log(error);
        }
    }, [spaceInfo, authUserInfo]);

    const checkStatus = useCallback(async (space_id, item_id) => {
        if (!item_id) return;
        const data = {
            space_id: space_id,
            item_id: item_id
        }
        if (authUserInfo) {
            data['user_id'] = authUserInfo.id;
        }
        const response = await checkMembershipItemStatus(data);
        if (response) {
            if (response.result) {
                const transaction = response.stripe_transaction;
                if (transaction) {
                    if (transaction.status !== 'inactive') {
                        const url = routes.viewUserSpace() + verifyEmailSpaceUsername;
                        const fin_url = url.replace(':space_username', '@' + verifyEmailSpaceUsername) + '?id=' + verifyEmailSpaceItemId;
                        // navigate(fin_url);
                    }
                } else {
                    const url = routes.viewUserSpace() + verifyEmailSpaceUsername;
                    const fin_url = url.replace(':space_username', '@' + verifyEmailSpaceUsername) + '?id=' + verifyEmailSpaceItemId;
                    // navigate(fin_url);
                }
            }
            setCheckStatusLoaded(true);
        }
    },[authUserInfo, verifyEmailSpaceUsername, verifyEmailSpaceItemId]);

    const activateSubscription = useCallback(async () => {
        setSubmitted(true);
        if (!verifyEmailSpaceItemId) return;
        const data = {
            space_id: spaceInfo.id,
            item_id: verifyEmailSpaceItemId,
            status: 'active'
        }
        if (authUserInfo) {
            data['user_id'] = authUserInfo.id;
        }
        const createStripeResponse = await updateMembershipItemStatus(data);
        if (createStripeResponse) {
            if (createStripeResponse && createStripeResponse.result) {
                const url = routes.viewUserSpace() + verifyEmailSpaceUsername;
                const fin_url = url.replace(':space_username', '@' + verifyEmailSpaceUsername) + '?id=' + verifyEmailSpaceItemId;
                navigate(fin_url);
            }
            setSubmitted(false);
        }
    },[authUserInfo, spaceInfo, verifyEmailSpaceUsername, verifyEmailSpaceItemId])

    useEffect(() => {
        if (verifyEmailSpaceUsername) {
            if (spaceInfoLoading && spaceInfoLoading === 'done') {
                if (spaceInfo && spaceInfo.id) {
                    getItem(spaceInfo.id, verifyEmailSpaceItemId).then((r) => {});
                    getMembershipDetailsQuery().then(r => {});
                }
            } else {
                if (!spaceInfoLoading && initialLoad) {
                    setInitialLoad(false);
                    dispatch(getSpaceInfo(verifyEmailSpaceUsername));
                }
            }
        }

        if (spaceInfo && verifyEmailSpaceItemId && authUserInfo) {
            checkStatus(spaceInfo.id, verifyEmailSpaceItemId).then(r => {});
        }
        //Redirect user to logged in page if not authenticated
        MembershipItemUtils.notAuthRedirect(loading, authUserInfo);
    }, [
        authUserInfo,
        spaceInfo,
        spaceInfoLoading,
        initialLoad,
        verifyEmailSpaceUsername,
        verifyEmailSpaceItemId,
        isSubmitted,
        isCheckStatusLoaded
    ]);

    const redirectToSpaceLink = (route, spaceUsername, prepend = '') => {
        const url = route.replace(':space_username', prepend + spaceUsername);
        window.location.replace(url);
    };

    return (
        <>
            <main id="cs-space-main" className="cs-main main-theme">
                {!spaceInfo || !authUserInfo || !isCheckStatusLoaded ? (
                    <section id="page-content-loading-overlay" className="screen-modal p-section-safeview theme-transparent">
                        <div className="centerframe-flex frame-modal">
                            <div className="block-lg modal-block">
                                <i className="fa-regular fa-solar-system"/>
                            </div>
                        </div>
                    </section>
                ) : (
                    <section id="cs-product-item-activate-c1" className="p-section-safeview theme-transparent">
                        <div className="centerframe-grid gap-2gutter">
                            <div id="cs-product-activate-main" className="block-lg order-1">
                                <hgroup className="heading">
                                    {(!membershipDetails?.active_membership && membershipDetails?.current_membership && membershipDetails?.current_membership.status === 'inactive') ? (
                                        <h1>Active Membership Required</h1>
                                    ) : (!membershipDetails?.active_membership && !membershipDetails?.current_membership) ? (
                                        <h1>Membership Required</h1>
                                    ) : (
                                        <h1>Item Activated</h1>
                                    )}

                                    {(!membershipDetails?.active_membership && membershipDetails?.current_membership && membershipDetails?.current_membership.status === 'inactive' ) ? (
                                        <p className="text-p2">Your new <a href={`/@${spaceInfo?.username ?? ''}`} target="_blank"><i className="fa-regular fa-solar-system"/> {spaceInfo?.name ?? ''}</a>
                                            {' '}Item requires a current Membership to be activated. You have an existing Membership, however it's not currently active.
                                        </p>
                                    ) : (!membershipDetails?.active_membership && !membershipDetails?.current_membership) ? (
                                        <p className="text-p2">Your new <a href={`/@${spaceInfo?.username ?? ''}`} target="_blank"><i className="fa-regular fa-solar-system"/> {spaceInfo?.name ?? ''}</a>
                                            {' '}Item requires a current Membership to be activated.</p>
                                    ) : (
                                        <p className="text-p2">Your new <a href={`/@${spaceInfo?.username ?? ''}`} target="_blank">
                                            <i className="fa-regular fa-solar-system"/> {spaceInfo?.name ?? ''}</a> Item is now active with your current Membership.
                                        </p>
                                    )}
                                </hgroup>

                                {(!membershipDetails?.active_membership && membershipDetails?.current_membership && membershipDetails?.current_membership.status === 'inactive' ) ? (
                                    <div className="cs-stack-title-preview">
                                        <h4>New Item</h4>
                                        <div className="cs-product-preview-row">
                                            <div className="avatar-square-gradient-md"><img src={item?.photo ?? ''} alt={item?.name ?? ''}/></div>
                                            <hgroup>
                                                <h5>{item?.name ?? ''}</h5>
                                                <p>{item?.summary ?? ''}</p>
                                            </hgroup>
                                        </div>
                                    </div>
                                ) : (membershipDetails?.active_membership && membershipDetails?.current_membership && membershipDetails?.current_membership.status === 'active' ) && (
                                    <div className="cs-stack-title-preview">
                                        <h4>New Item Active</h4>
                                        <div className="cs-product-preview-row">
                                            <div className="avatar-square-gradient-md"><img src={item?.photo ?? ''} alt={item?.name ?? ''}/></div>
                                            <hgroup>
                                                <h5>{item?.name ?? ''}</h5>
                                                <p>{item?.summary ?? ''}</p>
                                            </hgroup>
                                        </div>
                                    </div>
                                )}

                                <div className="cs-stack-title-preview">
                                    {(!membershipDetails?.active_membership && membershipDetails?.current_membership && membershipDetails?.current_membership.status === 'inactive' )? (
                                        <h4>Existing Membership <span>Inactive</span></h4>
                                    ) : (!membershipDetails?.active_membership && !membershipDetails?.current_membership) ? (
                                        <h4>New Item</h4>
                                    ) : (
                                        <h4>Current Membership</h4>
                                    )}
                                    <div className="cs-product-preview-row">
                                        {(!membershipDetails?.active_membership && membershipDetails?.current_membership && membershipDetails?.current_membership.status === 'inactive' )? (
                                            <>
                                                <div className="avatar-square-gradient-md"><img src={membershipDetails?.current_membership?.photo ?? ''}  alt={membershipDetails?.current_membership?.name ?? ''}/></div>
                                                <hgroup>
                                                    <h5>{membershipDetails?.current_membership?.name ?? ''}</h5>
                                                    <p>{membershipDetails?.current_membership?.summary ?? ''}</p>
                                                </hgroup>
                                            </>
                                        ) : (!membershipDetails?.active_membership && !membershipDetails?.current_membership) ? (
                                            <>
                                                <div className="avatar-square-gradient-md"><img src={item?.photo ?? ''}  alt={item?.name ?? ''}/></div>
                                                <hgroup>
                                                    <h5>{item?.name ?? ''}</h5>
                                                    <p>{item?.summary ?? ''}</p>
                                                </hgroup>
                                            </>
                                        ) : (
                                            <>
                                                <div className="avatar-square-gradient-md"><img src={membershipDetails?.active_membership?.photo ?? ''}  alt={membershipDetails?.active_membership?.name ?? ''}/></div>
                                                <hgroup>
                                                    <h5>{membershipDetails?.active_membership?.name ?? ''}</h5>
                                                    <p>{membershipDetails?.active_membership?.summary ?? ''}</p>
                                                </hgroup>
                                            </>
                                        )}
                                    </div>
                                </div>

                                {(!membershipDetails?.active_membership && membershipDetails?.current_membership && membershipDetails?.current_membership.status === 'inactive')? (
                                    <>
                                        <p className="text-p2">To activate your new Item, first reactivate your existing <a href={`/@${spaceInfo?.username ?? ''}`} target="_blank">
                                            <i className="fa-regular fa-solar-system"/> {spaceInfo?.name ?? ''}</a>{' '}
                                            Membership from your Dashboard or get a new Membership then activate it. Once you have an active <a href={`/@${spaceInfo?.username ?? ''}`} target="_blank"><i className="fa-regular fa-solar-system"/> {spaceInfo?.name ?? ''}</a> Membership, you can then activate any related Items in your Dashboard.
                                        </p>
                                    </>
                                ) : (!membershipDetails?.active_membership && !membershipDetails?.current_membership) ? (
                                    <p className="text-p2">To activate your new Item, first select and activate a new <a  href={`/@${spaceInfo?.username ?? ''}`}  target="_blank"><i className="fa-regular fa-solar-system"/> {spaceInfo?.name ?? ''}</a> Membership. Once it's active, you can then activate any related Items in your Dashboard.</p>
                                ) : (
                                    <p className="text-p2">You can always manage your Memberships and Items in your Dashboard.</p>
                                )}


                                <div className="stack-buttons col">
                                    {(!membershipDetails?.active_membership && membershipDetails?.current_membership && membershipDetails?.current_membership.status === 'inactive' )? (
                                        <>
                                            <a href={`/spaces`}type="button" className="button-primary-gradient"><span><p>View Memberships in Dashboard</p></span></a>
                                            <a href={`/@${spaceInfo?.username ? (spaceInfo?.username + '/member' ) : ''}`} type="button" className="button-secondary-gradient"><span><p>Browse Available Memberships</p></span></a>
                                        </>
                                    ) : (!membershipDetails?.active_membership && !membershipDetails?.current_membership) ? (
                                        <>
                                            <a href={`/@${spaceInfo?.username ? (spaceInfo?.username + '/member' ) : ''}`} type="button" className="button-primary-gradient"><span><p>Browse Available Memberships</p></span></a>
                                            <a href={`/spaces`} type="button" className="button-secondary-gradient"><span><p>View Item in Dashboard</p></span></a>
                                        </>
                                    ) : (
                                        <>
                                            <a href={`/spaces`} type="button" className="button-primary-gradient"><span><p>View in Dashboard</p></span></a>
                                            <a href={`/@${spaceInfo?.username ? (spaceInfo?.username + '/member' ) : ''}`} type="button" className="button-secondary-gradient"><span><p>Go to Members</p></span></a>
                                        </>
                                    )}
                                </div>
                            </div>
                            <aside id="cs-profile-create-aside" className="block-lg order-2 hidden lg:block">
                                {/*CSJS BG Image as inline style background.*/}
                                <div className="cs-profile-preview card sticky top-2gutter-xl"
                                     style={{backgroundImage: `url("${authUserInfo?.profile?.background_photo ?? ''}")`}}
                                >
                                    {/*CSJS Add class "shader" if background-image is applied in parent element cs-profile-preview, i.e when a user has uploaded a background.*/}
                                    <div className="cs-stack-profile-preview rounded-card stack-col shader">
                                        {/*CSJS Image as inline style background.*/}
                                        <div className="form-input-media lg circle air nohover"
                                             style={{backgroundImage: `url("${authUserInfo?.profile?.photo_thumb ?? ''}")`}}
                                        >
                                            <a href="#">
                                            </a>
                                        </div>
                                        <hgroup>
                                            {/*CSJS Add "active" class when content present.*/}
                                            <p className="text-p1 active">@{authUserInfo?.profile?.username ?? 'username'}</p>
                                            <h1>{authUserInfo?.profile?.display_name ?? 'Display Name'}</h1>
                                            <p className="text-p2 italic">
                                                {authUserInfo?.profile?.pronouns ?? 'Pronouns'}
                                            </p>
                                        </hgroup>
                                        <p className="italic">{authUserInfo?.profile?.intro ?? 'Short Bio goes here'}</p>
                                        {(authUserInfo?.profile) && (
                                            <SocialLinks
                                                socialLinks={authUserInfo.profile.social_links}
                                                parentClassName="cs-stack-icons stack-row-wrap"
                                                childClassName=""
                                                removeATag={true}
                                            />
                                        )}
                                        {/*<div className="cs-stack-icons stack-row-wrap">*/}
                                        {/*    /!*CSJS Only show if exists for User Profile.*!/*/}
                                        {/*    <i className="fa-brands fa-x-twitter"/>*/}
                                        {/*    /!*CSJS Only show if exists for User Profile.*!/*/}
                                        {/*    <i className="fa-brands fa-instagram"/>*/}
                                        {/*    /!*CSJS Only show if exists for User Profile.*!/*/}
                                        {/*    <i className="fa-brands fa-facebook-f"/>*/}
                                        {/*    /!*CSJS Only show if exists for User Profile.*!/*/}
                                        {/*    <i className="fa-brands fa-tiktok"/>*/}
                                        {/*    /!*CSJS Only show if exists for User Profile.*!/*/}
                                        {/*    <i className="fa-brands fa-linkedin-in"/>*/}
                                        {/*    /!*CSJS Only show if exists for User Profile.*!/*/}
                                        {/*    <i className="fa-brands fa-twitch"/>*/}
                                        {/*    /!*CSJS Only show if exists for User Profile.*!/*/}
                                        {/*    <i className="fa-regular fa-globe"/>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </section>
                    )}
            </main>
        </>
    );
};

export default memo(ItemActivateWalletConnect);
