import React, {useState, useCallback, useEffect} from 'react';

import AppLayout from './containers/AppLayout';
import fav from './assets/images/cs-favicon.png';
import {useLocation} from "react-router-dom";
import {getDataByUsername} from "./services/general";
// This is the main component of the application
const App = () => {
    const currentLocation = useLocation();
    const path = currentLocation.pathname;
    const [space, setSpace] = useState(null);
    
    useEffect(() => {
        if(!path.includes('@')){
            favicon.setAttribute("href", fav);
            appicon.setAttribute("href", fav);
        }else{
            const match = path.match(/@([^/]+)/);
            if (match) {
                const username = match[1];
                fetchViewData(username);
            }
        }
    }, [path]);



    useEffect(() => {
        if(space && space.pro_style){
            if(space.pro_style.favIcon){
                favicon.setAttribute("href", space?.pro_style?.favIcon);
                appicon.setAttribute("href", space?.pro_style?.favIcon);
            }else{
                favicon.setAttribute("href", fav);
                appicon.setAttribute("href", fav);
            }
        }else{
            favicon.setAttribute("href", fav);
            appicon.setAttribute("href", fav);
        }
    }, [space]);

    const fetchViewData = useCallback(
        async (username) => {
            try {
                console.log(username);
                // setIsSpaceLoaded(false);
                const data = {
                    username: username
                };

                const response = await getDataByUsername(data);
                if (response.result) {
                    setSpace(response.space);
                } else {

                }
                // setIsSpaceLoaded(true);

            } catch (error) {
                console.log(error);
            }
        },
        [space]
    );

	// Render the AppLayout component, which contains the layout of the app
	return <AppLayout />;
};

export default App;