import React, { memo, useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';

import routes from '../../../routes';
import { PRONOUNS } from '../../../constants/profile';
import { STORAGE_KEYS } from '../../../constants/common';
import { getCookie, getLocalStorage, setCookie } from '../../../utils/storage';
import { getDataByUsername } from '../../../services/general';
import {
	getUserByUsername,
	getProfileAboutMe,
	getUserSpaces,
	getMyCollections,
    addToTop8,
    getTop8,
    removeToTop8
} from '../../../services/user';
import {
	followUser,
	getFollowersList,
	getFollowingsList,
	unfollowUser
} from '../../../services/follow';
import { getListDisplay } from '../../../utils/list';
import { shortenNumber } from '../../../utils/number';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import Spaces from './Spaces';
import Collections from './Collections';
import Activity from './Activity';
import Following from './Following';
import Followers from './Followers';
import Top8 from './Top8';
import SpaceView from './../../SpaceView';
import SpaceViewPro from './../../SpaceView/SpaceViewPro';
import SpaceOffline from './../../SpaceView/SpaceOffline';
import SpaceOfflinePro from './../../SpaceView/SpaceOffline/Pro/SpaceOfflinePro';
import SpacePasswordProtected from '../../SpaceView/SpacePasswordProtected/SpacePasswordProtected';
import SpacePasswordProtectedPro from '../../SpaceView/SpacePasswordProtected/Pro/SpacePasswordProtectedPro';
import SpaceEmailInvitesOnly from '../../SpaceView/SpaceEmailInvitesOnly/SpaceEmailInvitesOnly';
import SpaceEmailInvitesOnlyPro from '../../SpaceView/SpaceEmailInvitesOnly/Pro/SpaceEmailInvitesOnlyPro';

import default_profile_card_bg from '../../../assets/images/profile_header.png';

import './UserView.scss';
import Pagination from 'react-bootstrap/Pagination';
import { displayPageContentLoader, hidePageContentLoader } from '../../../states/pageContentLoader';
import ReactPlayer from "react-player/lazy";
import SocialLinks from "../../../components/commons/SocialLinks";

const UserView = (props) => {
	const { globalActiveTab, spaceViewData, updateGlobalActiveTab, viewProfileUsername } = props;
	const currentLocation = useLocation();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [fetchingData, setFetchingData] = useState(false);
	const [userFound, setUserFound] = useState(false);
	const [viewUser, setViewUser] = useState(null);
	const [followingRequested, setFollowingRequested] = useState(false);
	const [backgroundBG, setBackgroundBG] = useState(null);
	const [aboutMeContent, setAboutMeContent] = useState('');
	const [fetchingAboutMe, setFetchingAboutMe] = useState(false);
	const [spaceData, setSpaceData] = useState(null);
	const [profileBackgroundVideoBlobPreview, setProfileBackgroundVideoBlobPreview] = useState(null);
	const [profileAssetType, setProfileAssetType] = useState(null);
	const [cardBackgroundType, setCardBackgroundType] = useState(null);
	const [spaceUsername, setSpaceUsername] = useState(null);
	const [initialLoad, setInitialLoad] = useState(true);
	const [spaceShowOfflineMode, setSpaceShowOfflineMode] = useState(false);
	const [spaceShowPasswordProtected, setSpaceShowPasswordProtected] = useState(false);
	const [spaceShowEmailInvitesOnly, setSpaceShowEmailInvitesOnly] = useState(false);
	const [initialSpaceFetchNoUser, setInitialSpaceFetchNoUser] = useState(false);
	const [passwordProtectedError, setPasswordProtectedError] = useState(false);
	const [spacesList, setSpacesList] = useState(null);
	const [spaceListLoading, setSpaceListLoading] = useState(false);
	const [followersListLoading, setFollowersListLoading] = useState(false);
	const [followingListLoading, setFollowingListLoading] = useState(false);
	const [collectionsList, setCollectionsList] = useState(null);
	const [followersList, setFollowersList] = useState(null);
	const [followingsList, setFollowingsList] = useState(null);
	const [followersCurrentPage, setFollowersCurrentPage] = useState(1);
	const [followingsCurrentPage, setFollowingsCurrentPage] = useState(1);
	const [followersPagination, setFollowersPagination] = useState([]);
	const [followingsPagination, setFollowingsPagination] = useState([]);
	const [userTop8, setUserTop8] = useState([]);
    const [isTop8, setIsTop8] = useState(false)

	const buildPagination = (type, current_page, max_page) => {
		const items = [];
		if (max_page) {
			for (let number = 1; number <= max_page; number++) {
				items.push(
					<Pagination.Item key={number} active={number === current_page}>
						{number}
					</Pagination.Item>
				);
			}
		}
		if (type === 'following') {
			setFollowingsPagination(items);
		}
		if (type === 'followers') {
			setFollowersPagination(items);
		}
	};

    const checkIfTop8 = () => {
        if (authUserInfo && viewUser) {
			if (authUserInfo.id !== viewUser?.id) {
                authUserInfo?.profile?.top_8.forEach(element => {
                    if(element.user_id === viewUser?.id){
                        setIsTop8(true);
                    }
                });
			}
		}
    }

    useEffect(() => {
        checkIfTop8()
    }, [authUserInfo, viewUser])

    useEffect(() => {
    }, [isTop8])

	const handleOnClickPagination = useCallback(
		async (event, type) => {
			const itemClicked = event.target.text;
			if (itemClicked) {
				const number = parseInt(itemClicked);
				if (type === 'followers') {
					setFollowersCurrentPage(number);
					await getFollowersRequest(number);
				}
				if (type === 'following') {
					setFollowingsCurrentPage(number);
					await getFollowingsRequest(number);
				}
			}
		},
		[viewUser]
	);

	const isOwnProfile = (viewUser, authUserInfo) => {
		if (authUserInfo && viewUser) {
			if (authUserInfo.id === viewUser?.id) {
				return true;
			}
		}
		return false;
	};

	const setSpacesCount = (viewUser) => {
		let count = viewUser?.spaces_count;
		if (viewUser?.spaces_count < viewUser?.owned_spaces_count) {
			count = viewUser?.owned_spaces_count;
		}
		return shortenNumber(count);
	};

	const setCollectionsCount = (viewUser) => {
		return shortenNumber(viewUser?.collections_count);
	};

	const setUserBackground = (user) => {
		if (user.profile.background_photo || user.profile.background_video) {
			if (user.profile.background_photo) {
				const space_bg = {
					backgroundImage: 'url(' + user.profile.background_photo + ')'
				};
				setBackgroundBG(space_bg);
				setCardBackgroundType('image');
			}
			if (user.profile.background_video) {
				setProfileBackgroundVideoBlobPreview(user.profile.background_video);
				setCardBackgroundType('video');
			}
		} else {
			const space_bg = {
				backgroundImage: 'url(' + default_profile_card_bg + ')'
			};
			setBackgroundBG(space_bg);
			setCardBackgroundType('image');
		}
	};

	const displayOwnProfileButtons = useCallback(() => {
		return isOwnProfile(viewUser, authUserInfo);
	}, [viewUser, authUserInfo]);

	const handleFollowUser = useCallback(() => {
		if (followingRequested) {
			return;
		}
		if (authUserInfo && viewUser) {
			const data = {
				user_id: authUserInfo.id,
				follow_id: viewUser?.id
			};
			setFollowingRequested(true);
			followUserRequest(data).then((r) => {});
		}
	}, [viewUser, authUserInfo, followingRequested]);

	const followUserRequest = async (data) => {
		try {
			let response;
			if (viewUser?.is_following) {
				response = await unfollowUser(data);
			} else {
				response = await followUser(data);
			}
			if (response && response.result) {
				await getUserProfile(viewUser?.profile.username);
			}
		} catch (error) {
			console.log(error);
			setFollowingRequested(false);
		}
	};

	const getTop8Request = async (passed_user) => {
		try {
			await getTop8({'user_id': passed_user.id})
				.then((response) => {
					setUserTop8(response.top_8)
					//dispatch(hidePageContentLoader());
					setUserBackground(passed_user);
					const data = {
						user_id: passed_user.id
					};
					getAboutRequest(data).then((r) => {});
					setFetchingData(false);
				})
				.catch((err) => {});
		} catch (error) {
			console.log(error);
		}
	};

	const getSpaceData = useCallback(
		async (username) => {
			try {
				const data = {
					username: username
				};
				const response = await getDataByUsername(data);
				if (response.result) {
					spaceViewData(response.space);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[]
	);

	const fetchViewData = useCallback(
		async (initialLoad, username, loggedInUser = null, view_space_password = null) => {
			if (username) {
				try {
					let ignoreSpaceNotActiveRedirect = false;
					let bypassFirst = false;
					setFetchingData(true);
					const data = {
						username: username
					};
					if (view_space_password) {
						setPasswordProtectedError(false);
						data.view_space_password = view_space_password;
					}
					if (loggedInUser) {
						data['viewing_user_id'] = loggedInUser.id;
					} else {
						if (!initialSpaceFetchNoUser) {
							setInitialSpaceFetchNoUser(true);
						} else {
							if (!userFound) {
								return;
							}
						}
					}
					const accessToken = JSON.parse(JSON.stringify(getLocalStorage(STORAGE_KEYS.ACCESS_TOKEN)));
					const refreshToken = JSON.parse(JSON.stringify(getLocalStorage(STORAGE_KEYS.REFRESH_TOKEN)));
					if (!loggedInUser && (accessToken && refreshToken)) {
						// wait for re-request with viewing_user_id param
						return;
					}
					await getDataByUsername(data)
						.then((response) => {
							//dispatch(displayPageContentLoader());
							let type = 'online';
							if (!response.result) {
								if (response.space_offline) {
									type = 'offline';
									setSpaceData(response.space);
									spaceViewData(response.space);
									//dispatch(hidePageContentLoader());
									setSpaceShowOfflineMode(true);
								} else if (response.email_invites_only) {
									type = 'email_invites_only';
									setSpaceData(response.space);
									//dispatch(hidePageContentLoader());
									setSpaceShowEmailInvitesOnly(true);
								} else {
									// on first load check if user token is available(user is logged in)
									// if YES and no 'viewing_user_id' is included on request payload, it means that fetch user request is still not done
									const accessToken = JSON.parse(
										JSON.stringify(getLocalStorage(STORAGE_KEYS.ACCESS_TOKEN))
									);
									const refreshToken = JSON.parse(
										JSON.stringify(getLocalStorage(STORAGE_KEYS.REFRESH_TOKEN))
									);

									if (response.password_protected) {
										type = 'password_protected';
										if (accessToken && refreshToken) {
											if (!loggedInUser) {
												ignoreSpaceNotActiveRedirect = true;
												bypassFirst = true;
											} else {
												ignoreSpaceNotActiveRedirect = true;
												bypassFirst = false;
											}
										} else {
											ignoreSpaceNotActiveRedirect = true;
											bypassFirst = false;
										}
									} else {
										if (accessToken && refreshToken) {
											if (!loggedInUser) {
												ignoreSpaceNotActiveRedirect = true;
												bypassFirst = true;
											}
										}
									}
								}
							} else {
								ignoreSpaceNotActiveRedirect = true;
							}
							if (loggedInUser) {
								if (type === 'email_invites_only' && initialLoad && loggedInUser) {
									console.log('rerequest');
								}
							} else {
								if (type === 'offline' || type === 'email_invites_only') {
									return;
								}
							}
							if (type === 'online') {
								setSpaceShowOfflineMode(false);
								setSpaceShowEmailInvitesOnly(false);
							}
							if (!ignoreSpaceNotActiveRedirect) {
								// navigate(routes.app());
								dispatch(hidePageContentLoader());
							} else {
								if (initialLoad) {
									if (response.user || (response.space && !response.password_protected)) {
										if (response.user) {
											setUserFound(true);
											setViewUser(response.user);
											getTop8Request(response.user).then((r) => {});
										} else {
											dispatch(
												getSpaceInfo(
													username,
													loggedInUser ? loggedInUser.id : null
												)
											);
											setSpaceData(response.space);
											setFetchingData(false);
											//dispatch(hidePageContentLoader());
										}
									} else {
										if (!bypassFirst) {
											if (response.password_protected) {
												setSpaceData(response.space);
												//dispatch(hidePageContentLoader());
												setSpaceShowPasswordProtected(true);
												setFetchingData(false);
											}
										}
									}
								} else {
									if (view_space_password) {
										if (response.space_not_found) {
											// navigate(routes.app());
											setPasswordProtectedError(true);
											setFetchingData(false);
										} else {
											// set 30 days expiration for view space password
											const today = new Date();
											const tomorrow = new Date();
											tomorrow.setDate(today.getDate() + 30);
											setCookie('view_space_password', view_space_password, {
												expires: tomorrow
											});
											dispatch(
												getSpaceInfo(
													username,
													loggedInUser ? loggedInUser.id : null
												)
											);
											setSpaceData(response.space);
											//dispatch(hidePageContentLoader());
											setSpaceShowPasswordProtected(false);
											setFetchingData(false);
										}
									}
								}
							}
						})
						.catch((err) => {});
				} catch (error) {
					console.log(error);
				}
			}
		},
		[userFound, authUserInfo, initialSpaceFetchNoUser, fetchingData]
	);

    useEffect(() => {
    }, [userTop8])

	const getUserProfile = async (username) => {
		try {
			const data = {
				username
			};
			if (authUserInfo) {
				data['viewing_user_id'] = authUserInfo.id;
			}
			//dispatch(hidePageContentLoader());
			await getUserByUsername(data).then((response) => {
				if (response && response.result) {
					setUserFound(true);
					setViewUser(response.user);
					setUserBackground(response.user);
                    let top8 = getTop8({ user_id: response.user.id })
                    setUserTop8(top8.top_8)
					const data = {
						user_id: response.user.id
					};
					getAboutRequest(data).then((r) => {});
					//dispatch(displayPageContentLoader());
				} else {
					console.log('user not found');
				}
				setFollowingRequested(false);
			});
		} catch (error) {
			console.log(error);
		}
	};

	const getAboutRequest = async (data) => {
		try {
			setFetchingAboutMe(true);
			const result = await getProfileAboutMe(data);
			if (result && result.result) {
				if (result.about_me) {
					setAboutMeContent(result.about_me);
				}
			}
			setFetchingAboutMe(false);
		} catch (error) {
			console.log(error);
		}
	};

	const getCollectionsRequest = async (data) => {
		try {
			const response = await getMyCollections(data);
			if (response && response.result) {
				setCollectionsList(response.collections);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getSpacesRequest = async (data) => {
		try {
			setSpaceListLoading(true);
			const result = await getUserSpaces(data);
			if (result && result.result) {
				setSpaceListLoading(false);
				setSpacesList(result.spaces);
			}
		} catch (error) {
			console.log(error);
			setSpaceListLoading(false);
		}
	};

	const getFollowingsRequest = useCallback(
		async (pageNumber = null) => {
			try {
				if (viewUser) {
					const data = {
						user_id: viewUser?.id,
						list_count: 10,
						page: pageNumber ? pageNumber : 1
					};
					setFollowingListLoading(true);
					const response = await getFollowingsList(data);
					if (response && response.result) {
						setFollowingListLoading(false);
						setFollowingsList(response.data);
						buildPagination(
							'following',
							parseInt(response.page),
							parseInt(response.page_range)
						);
					}
				}
			} catch (error) {
				console.log(error);
				setFollowingListLoading(false);
			}
		},
		[viewUser]
	);

	const getFollowersRequest = useCallback(
		async (pageNumber = null) => {
			try {
				if (viewUser) {
					const data = {
						user_id: viewUser?.id,
						list_count: 10,
						page: pageNumber ? pageNumber : 1
					};
					setFollowersListLoading(true);
					const response = await getFollowersList(data);
					if (response && response.result) {
						setFollowersListLoading(false);
						setFollowersList(response.data);
						buildPagination(
							'followers',
							parseInt(response.page),
							parseInt(response.page_range)
						);
					}
				}
			} catch (error) {
				console.log(error);
				setFollowersListLoading(false);
			}
		},
		[viewUser]
	);

	const handlePasswordSubmit = useCallback(
		async (data) => {
			fetchViewData(false, data.username, authUserInfo, data.password).then((r) => {});
		},
		[authUserInfo]
	);

	const handleGlobalActiveChange = useCallback((tab) => {
		updateGlobalActiveTab(tab);
	}, []);

	const checkSectionVisibility = useCallback(
		(section) => {
            // console.log("viewUser")
            // console.log(viewUser)
            // console.log(authUserInfo)
			if (viewUser && authUserInfo) {
				if (viewUser?.id === authUserInfo.id) {
					return true;
				}
			}
			let allowDisplay = false;
			if (viewUser && viewUser?.profile && viewUser?.profile.visibility) {
				const profileVisibilitySettings = viewUser?.profile.visibility;
				if (profileVisibilitySettings[section]) {
					const sectionVisibility = profileVisibilitySettings[section];
                    // console.log("sectionVisibility " + sectionVisibility)
					if (sectionVisibility === 'public') {
						allowDisplay = true;
					} else if (sectionVisibility === 'hidden') {
						allowDisplay = false;
					} else if (sectionVisibility === 'mutuals') {
						if (viewUser?.is_mutual) {
							allowDisplay = true;
						}
					} else if (sectionVisibility === 'private') {
						if (viewUser?.is_following) {
							allowDisplay = true;
						}
					} else {
						allowDisplay = true;
					}
				}
			}
            // console.log("allowDisplay " + allowDisplay)
			return allowDisplay;
		},
		[authUserInfo, viewUser]
	);

	const handleRefetchFollowings = useCallback(async () => {
		getFollowingsRequest(followingsCurrentPage).then((r) => {});
		getFollowersRequest(followersCurrentPage).then((r) => {});
	}, [followingsCurrentPage, followersCurrentPage, viewUser]);

	const handleRefetchFollowers = useCallback(async () => {
		getFollowersRequest(followersCurrentPage).then((r) => {});
		getFollowingsRequest(followingsCurrentPage).then((r) => {});
	}, [followersCurrentPage, followingsCurrentPage, viewUser]);

	const tabs = [
		{ id: 'about', condition: checkSectionVisibility('about_me'), active: ['home', 'about'] },
		// {
		// 	id: 'spaces',
		// 	condition:
		// 		checkSectionVisibility('spaces_list') &&
		// 		viewUser &&
		// 		(viewUser?.spaces_count > 0 || viewUser?.owned_spaces_count > 0)
		// },
		// {
		// 	id: 'collections',
		// 	condition:
		// 		checkSectionVisibility('collections') && viewUser && viewUser?.collections_count > 0
		// },
		// { id: 'activity', condition: checkSectionVisibility('activity') },
		{ id: 'followers', condition: checkSectionVisibility('follower_lists') },
		{ id: 'following', condition: checkSectionVisibility('follower_lists') }
	];

	useEffect(() => {
		if (viewUser || spaceData) {
			spaceViewData(spaceData);
			dispatch(hidePageContentLoader());
		} else {
			dispatch(displayPageContentLoader());
		}
	}, [spaceData, viewUser]);

	useEffect(() => {
		const path = window.location.pathname;
		let title = 'Commonspace';
		if (path && path.startsWith('/@') && path.length > 3) {
			let username = path.replace('/@', '');
			if (username.includes('/')) {
				if (username.includes('membership/claim')) {
					updateGlobalActiveTab('claim_membership');
				} else if (username.includes('membership/buy')) {
					updateGlobalActiveTab('buy_membership');
				} else if (username.includes('item/buy')) {
					updateGlobalActiveTab('buy_item');
				}
				if (username.includes('item/claim')) {
					updateGlobalActiveTab('claim_item');
				}
				const usernameExp = username.split('/');
				username = usernameExp[0] ?? '';
				setSpaceUsername(username);
			}

			// Check if path /@spaceusername/@profileusername exist
			if (viewProfileUsername) {
				// 	Get space data from the strip string space username to load the space header and footer
				getSpaceData(username).then(r => {});
				// assigned the profile username in the username to fetch the profile data
				username = viewProfileUsername;
			}

			title = 'Commonspace @' + username;
			if (!userFound) {
				const view_space_password = getCookie('view_space_password');
				if (view_space_password) {
					fetchViewData(initialLoad, username, authUserInfo, view_space_password).then((r) => {});
				} else {
					fetchViewData(initialLoad, username, authUserInfo).then((r) => {});
				}
			} else {
				fetchViewData(initialLoad, username, authUserInfo).then((r) => {});
			}
		}

		if (spaceUsername != null) {
			let isUserHome = false;
			if (userFound) {
				if (globalActiveTab === 'home') {
					isUserHome = true;
				}
			}

			if (isUserHome || globalActiveTab === 'about') {
				if (!path.includes('about')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + (viewProfileUsername ? '/@' + viewProfileUsername : '') + '/about');
				}
			} else if (globalActiveTab === 'spaces') {
				if (!path.includes('spaces')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + (viewProfileUsername ? '/@' + viewProfileUsername : '') + '/spaces');
				}
			} else if (globalActiveTab === 'collections') {
				if (!path.includes('collections')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + (viewProfileUsername ? '/@' + viewProfileUsername : '') + '/collections');
				}
			} else if (globalActiveTab === 'activity') {
				if (!path.includes('activities')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + (viewProfileUsername ? '/@' + viewProfileUsername : '') + '/activities');
				}
			} else if (globalActiveTab === 'following') {
				if (!path.includes('following')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + (viewProfileUsername ? '/@' + viewProfileUsername : '') + '/following');
				}
			} else if (globalActiveTab === 'followers') {
				if (!path.includes('followers')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + (viewProfileUsername ? '/@' + viewProfileUsername : '') + '/followers');
				}
			} else if (globalActiveTab === 'faq') {
				if (!path.includes('faq')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + (viewProfileUsername ? '/@' + viewProfileUsername : '') + '/faq');
				}
			} else if (globalActiveTab === 'terms-services') {
				if (!path.includes('terms-services')) {
					window.history.replaceState(
						null,
						null,
						'/@' + spaceUsername + (viewProfileUsername ? '/@' + viewProfileUsername : '') + '/terms-services'
					);
				}
			} else if (globalActiveTab === 'privacy-policy') {
				if (!path.includes('privacy-policy')) {
					window.history.replaceState(
						null,
						null,
						'/@' + spaceUsername + (viewProfileUsername ? '/@' + viewProfileUsername : '') + '/privacy-policy'
					);
				}
			} else if (globalActiveTab === 'support') {
				if (!path.includes('support')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + (viewProfileUsername ? '/@' + viewProfileUsername : '') + '/support');
				}
			}
		}

		if (authUserInfo && authUserInfo.profile) {
			if (spaceUsername === authUserInfo.profile.username) {
				if (authUserInfo.profile.photo_large) {
					setProfileAssetType('image');
				}
				if (authUserInfo.profile.video) {
					setProfileAssetType('video');
				}
				if (authUserInfo.profile.background_photo) {
					const space_bg = {
						backgroundImage: 'url(' + authUserInfo.profile.background_photo + ')'
					};
					setBackgroundBG(space_bg);
					setCardBackgroundType('image');
				}
				if (authUserInfo.profile.background_video) {
					setProfileBackgroundVideoBlobPreview(authUserInfo.profile.background_video);
					setCardBackgroundType('video');
				}
			}
		}

		document.title = title;

		if (viewUser) {
			if (viewUser?.profile.photo_large) {
				setProfileAssetType('image');
			}
			if (viewUser?.profile.video) {
				setProfileAssetType('video');
			}
			if (viewUser?.profile.background_photo) {
				const space_bg = {
					backgroundImage: 'url(' + viewUser?.profile.background_photo + ')'
				};
				setBackgroundBG(space_bg);
				setCardBackgroundType('image');
			}
			if (viewUser?.profile.background_video) {
				setProfileBackgroundVideoBlobPreview(viewUser?.profile.background_video);
				setCardBackgroundType('video');
			}
			if (globalActiveTab === 'home' || globalActiveTab === 'about') {
				getAboutRequest({ user_id: viewUser?.id }).then((r) => {});
			} else if (globalActiveTab === 'spaces') {
				let requestSpaces = false;
				if (spacesList) {
					if (spacesList.length === 0) {
						requestSpaces = true;
					}
				} else {
					requestSpaces = true;
				}

				if (requestSpaces) {
					getSpacesRequest({ user_id: viewUser?.id }).then((r) => {});
				}
			} else if (globalActiveTab === 'collections') {
				let requestCollections = false;
				if (collectionsList) {
					if (collectionsList.length === 0) {
						requestCollections = true;
					}
				} else {
					requestCollections = true;
				}
				if (requestCollections) {
					getCollectionsRequest({ user_id: viewUser?.id }).then((r) => {});
				}
			} else if (globalActiveTab === 'activity') {
			} else if (globalActiveTab === 'following') {
				let requestFollowings = false;
				if (followingsList) {
					if (followingsList.length === 0) {
						requestFollowings = true;
					}
				} else {
					requestFollowings = true;
				}
				if (requestFollowings) {
					getFollowingsRequest(followingsCurrentPage).then((r) => {});
				}
			} else if (globalActiveTab === 'followers') {
				let requestFollowers = false;
				if (followersList) {
					if (followersList.length === 0) {
						requestFollowers = true;
					}
				} else {
					requestFollowers = true;
				}
				if (requestFollowers) {
					getFollowersRequest(followersCurrentPage).then((r) => {});
				}
			} else {
				// others
			}
		}
		// console.log('useView: ' + globalActiveTab);
	}, [
		currentLocation.pathname,
		authUserInfo,
		followingRequested,
		globalActiveTab,
		spaceUsername,
		userFound,
		initialLoad,
		followingsCurrentPage,
		followersCurrentPage
	]);

    const addUserToTop8 = async () => {
        if (authUserInfo && viewUser) {
            let data = {
                user_id: authUserInfo.id,
                viewed_user_id: viewUser?.id
            }

            if(!isTop8){
                await addToTop8(data).then((r) => {
                    setIsTop8(true)
                });
            }else{
                await removeToTop8(data).then((r) => {
                    setIsTop8(false)
                });
            }

        }
    }

	useEffect(() => {}, [fetchingData]);

	return (
		<>
			{spaceShowOfflineMode || spaceShowPasswordProtected || spaceShowEmailInvitesOnly ? (
				<>
					{spaceShowOfflineMode && (
						<>
							{spaceData.plan === 'pro' ? (
								<SpaceOfflinePro spaceData={spaceData}/>
							) : (
								<SpaceOffline spaceData={spaceData}/>
							)}
						</>
					)}
					{spaceShowPasswordProtected && (
						<>
							{spaceData.plan === 'pro' ? (
								<SpacePasswordProtectedPro
									spaceData={spaceData}
									authUserInfo={authUserInfo}
									onPasswordSubmit={handlePasswordSubmit}
									displayPasswordError={passwordProtectedError}
								/>
							) : (
								<SpacePasswordProtected
									spaceData={spaceData}
									authUserInfo={authUserInfo}
									onPasswordSubmit={handlePasswordSubmit}
									displayPasswordError={passwordProtectedError}
								/>
							)}
						</>
					)}
					{spaceShowEmailInvitesOnly && (
						<>
							{spaceData.plan === 'pro' ? (
								<SpaceEmailInvitesOnlyPro
									spaceData={spaceData}
								/>
							) : (
								<SpaceEmailInvitesOnly
									spaceData={spaceData}
								/>
							)}
						</>
					)}
				</>
			) : (
				<>
					{spaceData ? (
						<>
							{spaceData.plan === 'pro' ? (
								<SpaceViewPro
									spaceData={spaceData}
									globalActiveTab={globalActiveTab}
									updateGlobalActiveTab={handleGlobalActiveChange}
								/>
							) : (
								<SpaceView
									spaceData={spaceData}
									globalActiveTab={globalActiveTab}
									updateGlobalActiveTab={handleGlobalActiveChange}
								/>
							)}
						</>
					) : (
						<>
							{!viewUser ?
								<section className="p-section-safeview theme-transparent">
									<div className="centerframe-grid gap-2gutter"/>
								</section>
								 :
								<main id="cs-space-main" className="cs-main main-theme" >
									<section id="cs-profile-banner" className={`p-0 bg-cover-center ${cardBackgroundType && cardBackgroundType === 'video' ? 'relative' : ''}`} style={backgroundBG}>
										{cardBackgroundType && cardBackgroundType === 'video' && (
											<ReactPlayer
												playing={true}
												loop={true}
												muted={true}
												controls={false}
												width="100%"
												height="100%"
												className={`videoPreview absolute`}
												url={profileBackgroundVideoBlobPreview}
											/>
										)}

										<div className="p-section-safeview bg-primary-shader-light backdrop-blur-lg relative">
											<div className={`centerframe-grid lg:gap-x-2gutter`}>

												<div id="cs-profile-banner-main" className="block-xl order-1">
													<div className="stack-col lg:stack-row">

														<div id="cs-profile-banner-avatar">
															<div className={`cs-profile-avatar`}>
																{profileAssetType && profileAssetType === 'video' ?
																	<ReactPlayer
																		playing={true}
																		loop={true}
																		muted={true}
																		controls={false}
																		width="100%"
																		height="100%"
																		className={`profile-image videoPreview `}
																		url={viewUser?.profile?.video}
																	/>
																	:
																	<img className="profile-image " src={viewUser?.profile?.photo_thumb}/>
																}
																<i className="fa-solid fa-badge-check"/>
															</div>
														</div>

														<div id="cs-profile-banner-info" className="stack-col">
															<p className="cs-profile-username">@{viewUser?.profile?.username}</p>
															{checkSectionVisibility('basics') && (
																<>
																	<div className="cs-stack-profile-displayname-pronouns stack-row-wrap">
																		<h1>{viewUser?.profile?.display_name}</h1>
																		<p>{getListDisplay(PRONOUNS, viewUser?.profile?.pronouns).toLowerCase()}</p>
																	</div>
																	<p className="cs-profile-bio">{viewUser?.profile?.intro}</p>

																	{(viewUser.profile && viewUser.profile?.social_links) && (
																		<SocialLinks
																			socialLinks={viewUser.profile.social_links}
																			parentClassName="cs-stack-link-icons stack-row-wrap"
																			childClassName="button-icon-round-gradient-alt"
																		/>
																	)}
																</>
															)}


															{/*<div className="cs-stack-link-icons stack-row-wrap">*/}
															{/*	{(viewUser?.profile?.social_link || viewUser?.profile?.url) && (*/}
															{/*		<>*/}
															{/*			/!*{viewUser?.profile?.social_link && (*!/*/}
															{/*			<a className="button-icon-round-gradient-alt">*/}
															{/*				<span><p><i className="fa-brands fa-x-twitter"></i></p></span>*/}
															{/*			</a>*/}
															{/*			/!*)}*!/*/}
															{/*			/!*{viewUser?.profile?.social_link && (*!/*/}
															{/*			<a className="button-icon-round-gradient-alt">*/}
															{/*				<span><p><i className="fa-brands fa-instagram"></i></p></span>*/}
															{/*			</a>*/}
															{/*			/!*)}*!/*/}
															{/*			/!*{viewUser?.profile?.social_link && (*!/*/}
															{/*			<a className="button-icon-round-gradient-alt">*/}
															{/*				<span><p><i className="fa-brands fa-facebook-f"></i></p></span>*/}
															{/*			</a>*/}
															{/*			/!*)}*!/*/}
															{/*			/!*{viewUser?.profile?.social_link && (*!/*/}
															{/*			<a className="button-icon-round-gradient-alt">*/}
															{/*				<span><p><i className="fa-brands fa-tiktok"></i></p></span>*/}
															{/*			</a>*/}
															{/*			/!*)}*!/*/}
															{/*			/!*{viewUser?.profile?.social_link && (*!/*/}
															{/*			<a className="button-icon-round-gradient-alt">*/}
															{/*				<span><p><i className="fa-brands fa-linkedin-in"></i></p></span>*/}
															{/*			</a>*/}
															{/*			/!*)}*!/*/}
															{/*			/!*{viewUser?.profile?.social_link && (*!/*/}
															{/*			<a className="button-icon-round-gradient-alt">*/}
															{/*				<span><p><i className="fa-brands fa-twitch"></i></p></span>*/}
															{/*			</a>*/}
															{/*			/!*)}*!/*/}
															{/*			/!*{viewUser?.profile?.url && (*!/*/}
															{/*			<a target="_blank" className="button-icon-round-gradient-alt">*/}
															{/*				<span><p><i className="fa-regular fa-globe"></i></p></span>*/}
															{/*			</a>*/}
															{/*			/!*)}*!/*/}
															{/*		</>*/}
															{/*	)}*/}
															{/*</div>*/}
															<div className="cs-stack-profile-stats stack-row">
																<div className="cs-stack-profile-stats-item stack-col-base">
																	<p>{shortenNumber(viewUser?.followers_count)}</p>
																	<p>Followers</p>
																</div>
																<div className="cs-stack-profile-stats-item stack-col-base">
																	<p>{shortenNumber(viewUser?.followings_count)}</p>
																	<p>Following</p>
																</div>
																<div className="cs-stack-profile-stats-item stack-col-base">
																	<p>{setSpacesCount(viewUser)}</p>
																	<p>Spaces</p>
																</div>
																<div className="cs-stack-profile-stats-item stack-col-base">
																	<p>{setCollectionsCount(viewUser)}</p>
																	<p>Items</p>
																</div>
															</div>
														</div>

													</div>
												</div>

												<div id="cs-profile-banner-actions" className="block-md order-2">
													<div className="cs-stack-buttons stack-col">
														{displayOwnProfileButtons() ? (
															<>
																<Link to={routes.profileEdit()} className="button-secondary-gradient-alt">
																<span>
																	<p>
																		<i className="fa fa-pencil me-2" aria-hidden="true"/>
																		Edit Profile
																	</p>
																</span>
																</Link>
																<Link to={routes.profileManageFollowers()} className="button-secondary-gradient-alt">
																<span>
																	<p>
																		<i className="fa fa-users me-2" aria-hidden="true"/>
																		Manage Followers
																	</p>
																</span>

																</Link>
																<Link to={routes.profileManageVisibility()} className="button-secondary-gradient-alt">
																<span>
																	<p>
																		<i className="fa fa-eye me-2" aria-hidden="true"/>
																		Manage Visibility
																	</p>
																</span>
																</Link>
																<Link to={routes.profile()} className="button-secondary-gradient-alt">
																<span>
																	<p>
																		<i className="fa fa-cog me-2" aria-hidden="true"/>
																		Visit Dashboard
																	</p>
																</span>
																</Link>
															</>
														) : (
															<>
																{authUserInfo && (
																	<>
																		<a onClick={handleFollowUser} target="_self" className="button-secondary-gradient-alt">
																		<span>
																			<p>
																				{followingRequested && (
																					<i className="fa-solid fa-spinner fa-spin"></i>
																				)}
																				{viewUser?.is_following ? 'Following' : 'Follow'}
																			</p>
																		</span>
																		</a>
																		<a onClick={addUserToTop8} target="_self" className="button-secondary-gradient-alt">
																		<span>
																			<p>
																				{!isTop8 ? 'Add To Top 8' : 'Remove from Top 8'}
																			</p>
																		</span>
																		</a>
																		<a target="_self" className="button-secondary-gradient-alt">
																		<span>
																			<p>
																				Contact
																			</p>
																		</span>
																		</a>
																		<a target="_self" className="button-secondary-gradient-alt">
																		<span>
																			<p>
																				Share
																			</p>
																		</span>
																		</a>
																	</>
																)}
															</>
														)}
													</div>
												</div>

											</div>
										</div>
									</section>

									{/*SECTION Container 1*/}
									{!fetchingData && (
										<section id="cs-profile-c1" className="px-section-safeview pt-0 theme-transparent">
											{!checkSectionVisibility('privacy') ? (
												<div className="centerframe-grid lg:gap-x-2gutter pt-5">
													<div id="cs-profile-about" className="stack-col block-full justify-self-center">
														<h2>This Account is Private</h2>
														<div className="cs-profile-about-info stack-col">
														<span>
															Follow @{viewUser?.profile?.username}?{' '}
															<Link to={routes.login()}>Log in</Link>{' '}
															to see their profile.
														</span>
														</div>
													</div>
												</div>
											) : (
												<>
													<div id="cs-profile-nav" className="centerframe-grid pt-gutter pb-gutter">
														<nav className="cs-nav-buttons-row block-full">
															<div className="stack-row-wrap lg:stack-row">
																{tabs.map((tab, index) => tab.condition && (
																	<a key={index} onClick={() => updateGlobalActiveTab(tab.id)}
																	   className={'button-secondary-gradient squared ' + ((tab.active ? tab.active.includes(globalActiveTab) : globalActiveTab === tab.id)
																		   ? 'active'
																		   : '')
																	   }>
																		<span>
																			<p>
																				{tab.id.charAt(0).toUpperCase() + tab.id.slice(1)}
																			</p>
																		</span>
																	</a>
																))}
															</div>
														</nav>
													</div>


													<div className="centerframe-grid lg:gap-x-2gutter">
														{checkSectionVisibility('about_me') &&
															<>
																{/*SECTION - PROFILE MAIN*/}
																{(globalActiveTab === 'home' || globalActiveTab === 'about') && (
																	<>
																		<div className="cs-profile-main block-xl order-1">

																			{/*SECTION - PROFILE ABOUT*/}
																			<div id="cs-profile-about" className="stack-col">

																				<div className="cs-title-icon-attribute stack-row-wrap split">
																					<h2>About</h2>
																					{viewUser?.profile?.visibility
																						.city !== 'hidden' && (
																						<p><i className="fa-light fa-location-dot"></i>
																							{viewUser?.profile?.location}
																						</p>
																					)}
																				</div>

																				<div className="cs-profile-about-info stack-col">
																					{fetchingAboutMe ? (
																						<div className="loader">
																							<div className="background"></div>
																							<i className="fa-solid fa-spin fa-spinner spinner gradient-color-txt"></i>
																						</div>
																					) : (
																						<>
																							{parse(aboutMeContent.toString())}
																						</>
																					)}
																				</div>

																			</div>
																			{/*!SECTION - PROFILE ABOUT*/}

																			{/*SECTION - PROFILE ABOUT*/}
																			{/*<div id="cs-profile-community-bio" className="stack-col">*/}

																			{/*	<div className="cs-title-icon-attribute stack-row-wrap split">*/}
																			{/*		<h3>Community Bio</h3>*/}
																			{/*		<p><i className="fa-light fa-key"></i>Joined January 2023</p>*/}
																			{/*	</div>*/}

																			{/*	<div className="cs-profile-about-info stack-col">*/}
																			{/*		<p>Your #FAM creator and host, join me at all the Friends & Mike events!</p>*/}
																			{/*	</div>*/}

																			{/*</div>*/}
																			{/*/!*!SECTION - PROFILE ABOUT*!/*/}

																			{/*/!*SECTION - PROFILE MODULES*!/*/}
																			{/*/!*SECTION - PROFILE MOD - COMMUNITY*!/*/}
																			{/*<div id="cs-mod-profile-community" className="stack-col">*/}

																			{/*	<div className="cs-title-icon-attribute stack-row-wrap split">*/}
																			{/*		<h3>Community Details</h3>*/}
																			{/*		<p><i className="fa-light fa-square-user"></i>Friends & Mike Membership</p>*/}
																			{/*	</div>*/}

																			{/*	<div className="cs-mod-profile-community-attributes stack-col">*/}
																			{/*		<div className="cs-datarow-attribute datarow">*/}
																			{/*			<div className="data-item">*/}
																			{/*				<h5>Favorite Food</h5>*/}
																			{/*			</div>*/}
																			{/*			<div className="data-item">*/}
																			{/*				<p>Tacos</p>*/}
																			{/*			</div>*/}
																			{/*		</div>*/}
																			{/*		<div className="cs-datarow-attribute datarow">*/}
																			{/*			<div className="data-item">*/}
																			{/*				<h5>Favorite City</h5>*/}
																			{/*			</div>*/}
																			{/*			<div className="data-item">*/}
																			{/*				<p>Tokyo</p>*/}
																			{/*			</div>*/}
																			{/*		</div>*/}
																			{/*		<div className="cs-datarow-attribute datarow">*/}
																			{/*			<div className="data-item">*/}
																			{/*				<h5>Favorite Spirit</h5>*/}
																			{/*			</div>*/}
																			{/*			<div className="data-item">*/}
																			{/*				<p>Tequila & Mezcal</p>*/}
																			{/*			</div>*/}
																			{/*		</div>*/}
																			{/*	</div>*/}

																			{/*</div>*/}
																			{/*/!*!SECTION - PROFILE MOD - COMMUNITY*!/*/}
																			{/*/!*SECTION - PROFILE MOD - SPOTIFY PLAYLIST*!/*/}
																			{/*<div id="cs-mod-profile-spotify-playlist" className="stack-col">*/}

																			{/*	<div className="cs-title-icon-attribute stack-row-wrap split">*/}
																			{/*		<h3>Current Playlist</h3>*/}
																			{/*		<p><i className="fa-light fa-list-music"></i>Coding Mode</p>*/}
																			{/*	</div>*/}
																			{/*	<iframe src="https://open.spotify.com/embed/playlist/37i9dQZF1DX5trt9i14X7j?utm_source=generator&theme=0" width="100%" height="352" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>*/}

																			{/*</div>*/}
																			{/*!SECTION - PROFILE MOD - SPOTIFY PLAYLIST*/}

																			{/*SECTION - PROFILE MOD - FEATURED LINKS*/}
																			{/*<div id="cs-mod-profile-featured-links" className="stack-col">*/}

																			{/*	<div className="cs-title-icon-attribute stack-row-wrap split">*/}
																			{/*		<h3>Featured Links</h3>*/}
																			{/*		<p><i className="fa-light fa-martini-glass-citrus"></i>My Favorite Flavor Explorations</p>*/}
																			{/*	</div>*/}

																			{/*	<div className="stack-col lg:stack-row">*/}

																			{/*		/!*ANCHOR LINKBOX: Featured Link Block*!/*/}
																			{/*		/!*CSJS Inline background-image URL is {mod_profile_featured_link_image} for specific Featured Link.*!/*/}
																			{/*		<article className="cs-featured-link card"*/}
																			{/*				 // style="background-image: url('images/profile-link-1.jpg');"*/}
																			{/*		>*/}
																			{/*			/!*CSJS URL is {mod_profile_featured_link_url} for specific Featured Link.*!/*/}
																			{/*			<a href="#featuredLinkURL" target="_self" className="rounded-card p-card">*/}
																			{/*				<hgroup>*/}
																			{/*					/!*CSJS Text is {mod_profile_featured_link_title} for specific Featured Link.*!/*/}
																			{/*					<h4>SAMPLED on Paramount+</h4>*/}
																			{/*					/!*CSJS Text is {mod_profile_featured_link_description} for specific Featured Link.*!/*/}
																			{/*					<p>A new travel show I co-created inviting you to experience music, culture, and food with touring musicians.</p>*/}
																			{/*				</hgroup>*/}
																			{/*			</a>*/}
																			{/*		</article>*/}

																			{/*		/!*ANCHOR LINKBOX: Featured Link Block*!/*/}
																			{/*		/!*CSJS Inline background-image URL is {mod_profile_featured_link_image} for specific Featured Link.*!/*/}
																			{/*		<article className="cs-featured-link card"*/}
																			{/*				 // style="background-image: url('images/profile-link-2.jpg');"*/}
																			{/*		>*/}
																			{/*			/!*CSJS URL is {mod_profile_featured_link_url} for specific Featured Link.*!/*/}
																			{/*			<a href="#featuredLinkURL" target="_self" className="rounded-card p-card">*/}
																			{/*				<hgroup>*/}
																			{/*					/!*CSJS Text is {mod_profile_featured_link_title} for specific Featured Link.*!/*/}
																			{/*					<h4>Japan Eats</h4>*/}
																			{/*					/!*CSJS Text is {mod_profile_featured_link_description} for specific Featured Link.*!/*/}
																			{/*					<p>Eat your way through Japan with my list of my favorite places to dine across the country.</p>*/}
																			{/*				</hgroup>*/}
																			{/*			</a>*/}
																			{/*		</article>*/}

																			{/*		/!*ANCHOR LINKBOX: Featured Link Block*!/*/}
																			{/*		/!*CSJS Inline background-image URL is {mod_profile_featured_link_image} for specific Featured Link.*!/*/}
																			{/*		<article className="cs-featured-link card"*/}
																			{/*				 // style="background-image: url('images/profile-link-3.jpg');"*/}
																			{/*		>*/}
																			{/*			/!*- CSJS URL is {mod_profile_featured_link_url} for specific Featured Link.*!/*/}
																			{/*			<a href="#featuredLinkURL" target="_self" className="rounded-card p-card">*/}
																			{/*				*/}
																			{/*				<hgroup>*/}
																			{/*					/!*CSJS Text is {mod_profile_featured_link_title} for specific Featured Link.*!/*/}
																			{/*					<h4>Global Drinks</h4>*/}
																			{/*					/!*CSJS Text is {mod_profile_featured_link_description} for specific Featured Link.*!/*/}
																			{/*					<p>Sip the best cocktails in the world, one drink at a time across dozens of cities on my global favorite bars list.</p>*/}
																			{/*				</hgroup>*/}
																			{/*			</a>*/}
																			{/*		</article>*/}

																			{/*	</div>*/}
																			{/*</div>*/}
																			{/*!SECTION - PROFILE MOD - FEATURED LINKS*/}

																		</div>
																		{/*!SECTION - PROFILE MAIN*/}


																		{/*- SECTION - PROFILE ASIDE*/}
																		<aside className="cs-profile-aside block-md order-2">

																			<div id="cs-top8" className="stack-col order-1">
																				{/*-- CSJS Count "8" should change based on items. Max 8.*/}
																				<h2>Top 8</h2>
																				<div className="cs-stack-top8 stack-row-wrap lg:stack-col">

																					{ userTop8 && userTop8.map((data, index) => (
																						<Top8
																							key={index}
																							index={index}
																							data={data}
																						/>
																					))}
																				</div>
																			</div>

																		</aside>
																		{/*!SECTION - PROFILE ASIDE*/}
																	</>
																)}
																{/* End of About section tab*/}
															</>
														}

														{globalActiveTab === 'spaces' && checkSectionVisibility('spaces_list') && (
															<>
																{spaceListLoading ? (
																	<section id="page-content-loading-overlay" className="screen-modal p-section-safeview theme-transparent">
																		<div className="centerframe-flex frame-modal">
																			<div className="block-lg modal-block">
																				<i className="fa-regular fa-solar-system"/>
																			</div>
																		</div>
																	</section>
																) : (
																	<Spaces spaces={spacesList} />
																)}
															</>
														)}
														{globalActiveTab === 'collections' && checkSectionVisibility('collections') && (
															<Collections
																collections={collectionsList}
															/>
														)}
														{globalActiveTab === 'activity' && checkSectionVisibility('activity') && (
															<Activity
																authUserInfo={authUserInfo}
																viewUserId={viewUser?.id}
															/>
														)}
														{globalActiveTab === 'following' && checkSectionVisibility('follower_lists') && (
															<>
																{followingListLoading ? (
																	<section id="page-content-loading-overlay" className="screen-modal p-section-safeview theme-transparent">
																		<div className="centerframe-flex frame-modal">
																			<div className="block-lg modal-block">
																				<i className="fa-regular fa-solar-system"/>
																			</div>
																		</div>
																	</section>
																) : (
																	<Following
																		data={followingsList}
																		authUserInfo={authUserInfo}
																		viewUserId={viewUser?.id}
																		refetch={
																			handleRefetchFollowings
																		}
																		top8={userTop8}
																	></Following>
																)}
															</>
														)}
														{globalActiveTab === 'followers' &&
															checkSectionVisibility('follower_lists') && (
																<>
																	{followersListLoading ? (
																		<section id="page-content-loading-overlay" className="screen-modal p-section-safeview theme-transparent">
																			<div className="centerframe-flex frame-modal">
																				<div className="block-lg modal-block">
																					<i className="fa-regular fa-solar-system"/>
																				</div>
																			</div>
																		</section>
																	) : (
																		<Followers
																			data={followersList}
																			authUserInfo={authUserInfo}
																			viewUserId={viewUser?.id}
																			refetch={handleRefetchFollowers}
																			top8={userTop8}
																		/>
																	)}
																</>
															)}
													</div>
													<div className="mt-4">
														{globalActiveTab === 'following' &&
															followingsPagination.length > 1 && (
																<Pagination
																	size="sm"
																	onClick={(event) =>
																		handleOnClickPagination(
																			event,
																			'following'
																		)
																	}
																>
																	{followingsPagination}
																</Pagination>
															)}
														{globalActiveTab === 'followers' &&
															followersPagination.length > 1 && (
																<Pagination
																	size="sm"
																	onClick={(event) =>
																		handleOnClickPagination(
																			event,
																			'followers'
																		)
																	}
																>
																	{followersPagination}
																</Pagination>
															)}
													</div>
												</>
											)}
										</section>
									)}


									{/*<div className="w-full pb-5 min-h-[336px]">*/}
									{/*	{!fetchingData && (*/}
									{/*		<div id="cs-profile-c1" class="px-section-safeview pt-0 theme-transparent">*/}
									{/*			{!checkSectionVisibility('privacy') ? (*/}
									{/*				<div class="centerframe-grid lg:gap-x-2gutter">*/}
									{/*                    <div class="cs-profile-main block-xl order-1">*/}
									{/*                        <div id="cs-profile-about" class="stack-col">*/}
									{/*                                <h2>This Account is Private</h2>*/}
									{/*                                <div class="cs-profile-about-info stack-col">*/}
									{/*                                <span>*/}
									{/*                                    Follow @{viewUser?.profile?.username}?{' '}*/}
									{/*                                    <Link to={routes.login()}>Log in</Link>{' '}*/}
									{/*                                    to see their profile.*/}
									{/*                                </span>*/}
									{/*                                </div>*/}

									{/*                        </div>*/}
									{/*                    </div>*/}
									{/*                <div>*/}

									{/*					</div>*/}
									{/*				</div>*/}
									{/*			) : (*/}
									{/*                <div id="cs-profile-nav" class="centerframe-grid pt-gutter pb-gutter">*/}
									{/*                    <nav class="cs-nav-buttons-row block-full">*/}
									{/*                        <div class="stack-row-wrap lg:stack-row">*/}
									{/*                        {tabs.map(*/}
									{/*								(tab, index) =>*/}
									{/*									tab.condition && (*/}
									{/*                                        <a key={index} onClick={() =>*/}
									{/*													updateGlobalActiveTab(*/}
									{/*														tab.id*/}
									{/*													)*/}
									{/*												} className={*/}
									{/*													'button-secondary-gradient squared ' +*/}
									{/*													((*/}
									{/*														tab.active*/}
									{/*															? tab.active.includes(*/}
									{/*																	globalActiveTab*/}
									{/*															  )*/}
									{/*															: globalActiveTab ===*/}
									{/*															  tab.id*/}
									{/*													)*/}
									{/*														? 'active'*/}
									{/*														: '')*/}
									{/*												}><span><p>*/}
									{/*                                                    {tab.id*/}
									{/*													.charAt(0)*/}
									{/*													.toUpperCase() +*/}
									{/*													tab.id.slice(1)}*/}
									{/*                                                </p></span></a>*/}
									{/*                                    ))}*/}


									{/*                        </div>*/}
									{/*                    </nav>*/}
									{/*                </div>*/}

									{/*			)}*/}

									{/*			<div className="w-full">*/}
									{/*				/!*{(globalActiveTab === 'home' || globalActiveTab === 'about') && (*!/*/}
									{/*                /!*    <div class="centerframe-grid lg:gap-x-2gutter">*!/*/}
									{/*                /!*        <div class="cs-profile-main block-xl order-1">*!/*/}
									{/*                /!*            <div id="cs-profile-about" class="stack-col">*!/*/}
									{/*                /!*                {checkSectionVisibility('about_me') && (*!/*/}
									{/*                /!*                    <div class="cs-title-icon-attribute stack-row-wrap split">*!/*/}
									{/*                /!*                        <h2>About</h2>*!/*/}
									{/*                /!*                        {viewUser?.profile.visibility*!/*/}
									{/*                /!*                            .city !== 'hidden' && (*!/*/}
									{/*                /!*                                <p><i class="fa-light fa-location-dot"></i>*!/*/}
									{/*                /!*                                    {*!/*/}
									{/*                /!*                                        viewUser*!/*/}
									{/*                /!*                                            .profile*!/*/}
									{/*                /!*                                            .location*!/*/}
									{/*                /!*                                    }*!/*/}
									{/*                /!*                                </p>*!/*/}
									{/*                /!*                            )}*!/*/}

									{/*                /!*                    </div>*!/*/}
									{/*                /!*                )}*!/*/}
									{/*                /!*            </div>*!/*/}
									{/*                /!*            <div class="cs-profile-about-info stack-col">*!/*/}
									{/*                /!*                {fetchingAboutMe ? (*!/*/}
									{/*                /!*                    <>*!/*/}
									{/*                /!*                        <div className="loader">*!/*/}
									{/*                /!*                            <div className="background"></div>*!/*/}
									{/*                /!*                            <i className="fa-solid fa-spin fa-spinner spinner gradient-color-txt"></i>*!/*/}
									{/*                /!*                        </div>*!/*/}
									{/*                /!*                    </>*!/*/}
									{/*                /!*                ) : (*!/*/}
									{/*                /!*                    <>*!/*/}
									{/*                /!*                        {parse(*!/*/}
									{/*                /!*                            aboutMeContent.toString()*!/*/}
									{/*                /!*                        )}*!/*/}
									{/*                /!*                    </>*!/*/}
									{/*                /!*                )}*!/*/}
									{/*                /!*            </div>*!/*/}
									{/*                /!*        </div>*!/*/}
									{/*                /!*        {userTop8 && userTop8.length > 0 && !checkSectionVisibility('privacy') && (*!/*/}
									{/*                /!*            <aside class="cs-profile-aside block-md order-2">*!/*/}

									{/*                /!*                <h2>Top 8</h2>*!/*/}
									{/*                /!*                <div class="cs-stack-top8 stack-row-wrap lg:stack-col">*!/*/}
									{/*                /!*                    { userTop8.map((data, index) => (*!/*/}
									{/*                /!*                            <Top8*!/*/}
									{/*                /!*                                key={index}*!/*/}
									{/*                /!*                                data={data}*!/*/}
									{/*                /!*                            ></Top8>*!/*/}
									{/*                /!*                    ))}*!/*/}
									{/*                /!*                </div>*!/*/}
									{/*                /!*            </aside>*!/*/}
									{/*                /!*        )}*!/*/}

									{/*                /!*    </div>*!/*/}

									{/*				/!*)}*!/*/}

									{/*				{globalActiveTab === 'spaces' && checkSectionVisibility('spaces_list') && (*/}
									{/*						<>*/}
									{/*							{spaceListLoading ? (*/}
									{/*								<div className="loading-items">*/}
									{/*									<i className="fa-light fa-solar-system gradient-color-txt rotate"></i>*/}
									{/*								</div>*/}
									{/*							) : (*/}
									{/*								<Spaces spaces={spacesList} />*/}
									{/*							)}*/}
									{/*						</>*/}
									{/*					)}*/}

									{/*				{globalActiveTab === 'collections' &&*/}
									{/*					checkSectionVisibility('collections') && (*/}
									{/*						<Collections*/}
									{/*							collections={collectionsList}*/}
									{/*						></Collections>*/}
									{/*					)}*/}
									{/*				{globalActiveTab === 'activity' &&*/}
									{/*					checkSectionVisibility('activity') && (*/}
									{/*						<Activity*/}
									{/*							authUserInfo={authUserInfo}*/}
									{/*							viewUserId={viewUser?.id}*/}
									{/*						></Activity>*/}
									{/*					)}*/}
									{/*				{globalActiveTab === 'following' &&*/}
									{/*					checkSectionVisibility('follower_lists') && (*/}
									{/*						<>*/}
									{/*							{followingListLoading ? (*/}
									{/*								<div className="loading-items">*/}
									{/*									<i className="fa-light fa-solar-system gradient-color-txt rotate"></i>*/}
									{/*								</div>*/}
									{/*							) : (*/}
									{/*								<Following*/}
									{/*									data={followingsList}*/}
									{/*									authUserInfo={authUserInfo}*/}
									{/*									viewUserId={viewUser?.id}*/}
									{/*									refetch={*/}
									{/*										handleRefetchFollowings*/}
									{/*									}*/}
									{/*								></Following>*/}
									{/*							)}*/}
									{/*						</>*/}
									{/*					)}*/}
									{/*				{globalActiveTab === 'followers' &&*/}
									{/*					checkSectionVisibility('follower_lists') && (*/}
									{/*						<>*/}
									{/*							{followersListLoading ? (*/}
									{/*								<div className="loading-items">*/}
									{/*									<i className="fa-light fa-solar-system gradient-color-txt rotate"></i>*/}
									{/*								</div>*/}
									{/*							) : (*/}
									{/*								<Followers*/}
									{/*									data={followersList}*/}
									{/*									authUserInfo={authUserInfo}*/}
									{/*									viewUserId={viewUser?.id}*/}
									{/*									refetch={handleRefetchFollowers}*/}
									{/*								></Followers>*/}
									{/*							)}*/}
									{/*						</>*/}
									{/*					)}*/}
									{/*			</div>*/}
									{/*			<div className="mt-4">*/}
									{/*				{globalActiveTab === 'following' &&*/}
									{/*					followingsPagination.length > 1 && (*/}
									{/*						<Pagination*/}
									{/*							size="sm"*/}
									{/*							onClick={(event) =>*/}
									{/*								handleOnClickPagination(*/}
									{/*									event,*/}
									{/*									'following'*/}
									{/*								)*/}
									{/*							}*/}
									{/*						>*/}
									{/*							{followingsPagination}*/}
									{/*						</Pagination>*/}
									{/*					)}*/}
									{/*				{globalActiveTab === 'followers' &&*/}
									{/*					followersPagination.length > 1 && (*/}
									{/*						<Pagination*/}
									{/*							size="sm"*/}
									{/*							onClick={(event) =>*/}
									{/*								handleOnClickPagination(*/}
									{/*									event,*/}
									{/*									'followers'*/}
									{/*								)*/}
									{/*							}*/}
									{/*						>*/}
									{/*							{followersPagination}*/}
									{/*						</Pagination>*/}
									{/*					)}*/}
									{/*			</div>*/}
									{/*		</div>*/}
									{/*	)}*/}
									{/*</div>*/}
								</main>
							}

						</>
					)}
				</>
			)}
		</>
	);
};

export default memo(UserView);
